<template>
  <e-toolbar>
    <div class="flex space-x-8">
      <button-with-menu-factory
        id="interruption"
        label="Beurlaubung"
        :disabled="isInterruptionDisabled || isProfileOwnedByCARE"
        icon="island"
        :items="interruptionItems"
        @menuButtonClicked="handleMenuButtonClicked"
      />
      <e-button
        variant="text"
        small
        data-test="activate-profile-accomodation-button"
        :disabled="profileAccomodationActive || isProfileOwnedByCARE"
        @click="handleMenuButtonClicked(setProfileAccomodationEvent)"
      >
        <e-icon icon="clock-plus" class="mr-1" size="xs" />
        Nachteilsausgleich aktivieren
      </e-button>
      <e-button
        v-if="isResignateOwnershipButtonEnabled && !isProfileOwnedByCARE"
        variant="text"
        small
        :disabled="false"
        @click="handleMenuButtonClicked(resignateOwnership)"
      >
        In CARE bearbeiten
      </e-button>
    </div>
  </e-toolbar>
</template>

<script>
import { EToolbar, EButton, EIcon } from '@careerpartner/nitro';
import ButtonWithMenuFactory from '@/components/BookingDetailToolbar/shared/ButtonWithMenuFactory';
import { INTERRUPTION_EVENTS } from '@/common/events/interruptionEvents';
import { PROFILE_ACCOMODATION_EVENTS } from '@/common/events/profileAccomodationEvents';
import { OWNERSHIP_EVENTS } from '@/common/events/ownershipEvents';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import {
  API_ENDPOINTS,
  FEATURES,
  ENROLMENT_STATUS_STRINGS,
} from '@/common/constants';
import dayjs from 'dayjs';
import { ApiServiceWrapper } from '@/utils/ApiServiceWrapper';
import { isInactive } from '@/utils/feature-config';

export default {
  name: 'BookingOverviewToolbar',
  components: {
    ButtonWithMenuFactory,
    EToolbar,
    EButton,
    EIcon,
  },
  data() {
    return {
      interruption: false,
    };
  },
  computed: {
    bookings() {
      return this.$store.getters[`booking/${BookingGetters.GET_BOOKINGS}`];
    },
    isInterruptionDisabled() {
      if (isInactive(FEATURES.INTERRUPTION)) {
        return true;
      }

      const today = dayjs();
      const bookingStarted = this.bookings.some(
        (booking) => today >= dayjs(booking.startDate),
      );

      const bookingInStudies = this.bookings.some((booking) =>
        [ENROLMENT_STATUS_STRINGS.STUDYING].includes(booking.status?.name),
      );

      const bookingInterrupted = this.bookings.some(
        (booking) =>
          ENROLMENT_STATUS_STRINGS.INTERRUPTED === booking.status?.name,
      );

      if (bookingInterrupted) {
        return false;
      }

      return !(bookingStarted && bookingInStudies);
    },
    interruptionItems() {
      return [
        {
          id: INTERRUPTION_EVENTS.ON_SET_INTERRUPTION,
          text: 'Beurlaubung hinzufügen',
          event: 'menuButtonClicked',
          showBottomSeparator: false,
        },
        {
          id: INTERRUPTION_EVENTS.ON_RECALL_INTERRUPTION,
          text: 'Beurlaubung widerrufen',
          disabled: !this.interruption,
          event: 'menuButtonClicked',
          showBottomSeparator: false,
        },
      ];
    },
    profileAccomodationActive() {
      return this.$store.getters[
        `profile/${ProfileGetters.PROFILE_ACCOMODATION_ACTIVE}`
      ];
    },
    setProfileAccomodationEvent() {
      return PROFILE_ACCOMODATION_EVENTS.ON_SET_PROFILE_ACCOMODATION;
    },
    resignateOwnership() {
      return OWNERSHIP_EVENTS.ON_RESIGNATE_OWNERSHIP;
    },
    isResignateOwnershipButtonEnabled() {
      return this.$isActive(FEATURES.SEPERATE_PROFILE_OWNERSHIP);
    },
    id() {
      return this.$route.params.id;
    },
    isProfileOwnedByCARE() {
      return this.$store.getters[
        `profile/${ProfileGetters.IS_PROFILE_OWNED_BY_CARE}`
      ];
    },
  },
  async beforeMount() {
    this.interruption = await this.hasPendingInterruption();
  },
  methods: {
    handleMenuButtonClicked(e) {
      this.$emit('toolbar-action', e);
    },
    async hasPendingInterruption() {
      try {
        const profileId =
          this.$store.getters[`profile/${ProfileGetters.PROFILE_ID}`];

        const requestUrl = API_ENDPOINTS.INTERRUPTION.replace(
          ':profileId',
          profileId,
        );

        const interruption = await ApiServiceWrapper.get(requestUrl);

        if (!interruption) {
          return false;
        }

        const startDate = dayjs(interruption.data.startDate);
        const endDate = startDate.add(interruption.data.period, 'month');

        return endDate.isAfter(dayjs());
      } catch (e) {
        return false;
      }
    },
  },
};
</script>
