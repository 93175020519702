var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.waitingForResponse)?_c('Loader'):_c('process-modal-form',{attrs:{"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitRecognition},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',[_c('validation-provider',{attrs:{"name":"Erreichte ECTS Anzahl","slim":"","immediate":"","rules":{
          required: true,
          numeric: true,
          min_value: {
            value: _vm.ects,
            min: 1,
          },
          max_value: {
            value: _vm.ects,
            max: _vm.maxEctsAmount,
          },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"ects-amount","label":"Erreichte ECTS Anzahl","wide":"","errors":errors,"required":"","type":"number"},model:{value:(_vm.ects),callback:function ($$v) {_vm.ects=_vm._n($$v)},expression:"ects"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"recognition-type","searchable":"","options":_vm.recognitionTypes,"option-label":"name","track-by":"id","label":"Art der Anerkennung","errors":errors,"required":"","mapped":""},model:{value:(_vm.recognitionType),callback:function ($$v) {_vm.recognitionType=$$v},expression:"recognitionType"}})]}}])})],1)]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Anerkennen")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }