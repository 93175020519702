<template>
  <e-chip :key="status.id" :class="status.class" class="m-2">
    <e-icon :icon="status.icon" size="xs" />
    {{ status.text }}
    <slot name="additionalContent"></slot>
  </e-chip>
</template>

<script>
import { ADMISSION_PROGRAM_STATUS } from '@/common/constants';
import { EChip, EIcon } from '@careerpartner/nitro';

export const allAdmissionStatus = [
  {
    id: ADMISSION_PROGRAM_STATUS.IN_PROGRESS,
    icon: 'bank-outline',
    text: 'in Durchführung',
    class: 'bg-gray-600 text-white hover:bg-gray-800 group-hover:bg-gray-800',
  },
  {
    id: ADMISSION_PROGRAM_STATUS.RESULTS_PENDING,
    icon: 'timer-sand',
    text: 'Ergebnis ausstehend',
    class:
      'bg-status-yellow text-black hover:bg-yellow-600 group-hover:bg-yellow-600',
  },

  {
    id: ADMISSION_PROGRAM_STATUS.PASSED,
    icon: 'check',
    text: 'bestanden',
    class:
      'bg-green-600 text-white hover:bg-green-700 group-hover:bg-green-700',
  },

  {
    id: ADMISSION_PROGRAM_STATUS.FAILED,
    icon: 'close',
    text: 'Endgültig nicht bestanden',
    class: 'bg-status-red text-white hover:bg-red-600 group-hover:bg-red-600',
  },
];

export default {
  name: 'AdmissionStatus',

  components: {
    EChip,
    EIcon,
  },

  props: {
    value: { type: String, default: undefined },
  },
  computed: {
    status() {
      const status = allAdmissionStatus.find((s) => s.id === this.value);

      if (!status) {
        return {
          id: null,
          icon: '',
          text: '-',
          class: 'hidden',
        };
      }

      return status;
    },
  },
};
</script>
