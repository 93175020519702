<template>
  <e-modal
    size="sm"
    :is-open="value"
    allow-overflow
    :auto-height="true"
    :error="error"
    @close="closeModal"
  >
    <div
      class="
        p-5
        grid grid-cols-6
        gap-y-3
        place-items-center
        grid-flow-row
        text-center
      "
    >
      <div class="col-span-12">
        <img :src="trashCanImage" alt="" class="h-20" />
      </div>

      <div class="col-span-12">
        <h2 class="font-bold">
          Bist du sicher, dass du den Curriculumwechsel widerrufen möchtest?
        </h2>
      </div>
      <div class="col-span-6">
        <e-button variant="secondary" @click="closeModal">
          <span class="text-black">Abbrechen</span>
        </e-button>
      </div>
      <div class="col-span-6 justify-end ml-1">
        <e-button variant="primary" @click="submitCancelCurriculumChange">
          <span>Widerruf</span>
        </e-button>
      </div>
    </div>
  </e-modal>
</template>

<script>
import { API_ENDPOINTS, CURRICULUM_CHANGE_STATUS } from '@/common/constants';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { Actions as AppActions } from '@/store/modules/app/types';
import { EModal, EButton } from '@careerpartner/nitro';
import { imagePaths } from '@/common/content';

export default {
  name: 'CancelCurriculumChangeModal',
  components: {
    EModal,
    EButton,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    booking: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: '',
      waitingForResponse: false,
      ready: false,
      userParameters: {},
    };
  },
  computed: {
    trashCanImage() {
      return imagePaths.TRASH_CAN_IMAGE_OF_DELETE_ADMISSION_MODAL;
    },
  },

  methods: {
    closeModal() {
      this.$emit('input', false);
    },
    async submitCancelCurriculumChange() {
      if (!this.booking.curriculumChangeRequests) {
        return;
      }

      const ccr = this.booking.curriculumChangeRequests.find((ccr) =>
        [
          CURRICULUM_CHANGE_STATUS.PENDING,
          CURRICULUM_CHANGE_STATUS.APPLIED,
        ].includes(ccr.status),
      );

      if (!ccr) {
        return;
      }

      const requestUrl = API_ENDPOINTS.CANCEL_CURRICULUM_CHANGE.replace(
        ':bookingId',
        this.$route.params.bookingId,
      ).replace(':changeId', ccr.id);

      this.waitingForResponse = true;

      try {
        await ApiServiceWrapper.post(requestUrl);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Curriculumwechsel erfolgreich widerrufen.',
        });

        this.$emit('curriculum-change-canceled-successfully');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
