<template>
  <div>
    <div class="font-bold text-blue-400 mb-4">
      <slot name="headline">
        <h2>{{ headline }}</h2>
      </slot>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    headline: { type: String, default: undefined },
  },
};
</script>
