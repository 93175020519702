var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('Loader'):_c('process-modal-form',{attrs:{"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitChangeRevocationRequst},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',{staticClass:"grid grid-cols-6 gap-x-6 mt-6 gap-y-3 grid-flow-row"},[_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Neue Widerrufsfrist","rules":{
            date_format: true,
            after: { date: _vm.revocationDateMin, inclusive: true },
            before: { date: _vm.revocationDateMax, inclusive: true },
          },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"recall-interruption-revocationDate","type":"date","label":"Neue Widerrufsfrist","wide":"","errors":errors,"required":""},model:{value:(_vm.revocationDate),callback:function ($$v) {_vm.revocationDate=$$v},expression:"revocationDate"}})]}}])})],1),_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Grund angeben","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"change-revocation-period-reason","searchable":"","options":_vm.availableReasons,"option-label":"name","track-by":"key","label":"Grund angeben","errors":errors,"required":"","mapped":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})]}}])})],1),(_vm.isOtherReason)?_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Neuen Grund angeben","rules":"required|max:100","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"change-revocation-period-otherReason","label":"Neuen Grund angeben","errors":errors},model:{value:(_vm.reasonOther),callback:function ($$v) {_vm.reasonOther=$$v},expression:"reasonOther"}})]}}],null,false,1441712438)})],1):_vm._e()])]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Speichern")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }