<template>
  <process-modal-form
    :id="id"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitRecall"
  >
    <template #form-content>
      <div class="flex space-x-4">
        <validation-provider
          v-if="!isAddTrialPeriod"
          v-slot="{ errors }"
          name="Ende Probestudium"
          :rules="
            isGraduateTrialPeriod
              ? {
                  required: true,
                  date_format: true,
                  after: { date: studyStart, inclusive: true },
                  before: { date: todayDate, inclusive: true },
                }
              : {}
          "
          slim
          immediate
        >
          <e-text-field
            v-show="!isAddTrialPeriod"
            id="trial-period-end-date"
            v-model="endDate"
            type="date"
            label="Ende Probestudium"
            :disabled="!isGraduateTrialPeriod"
            wide
            :errors="errors"
            required
          />
        </validation-provider>

        <!-- Add trial period -->
        <validation-provider
          v-slot="{ errors }"
          name="Dauer Probestudium"
          rules="required"
          slim
        >
          <e-multiselect
            v-show="isAddTrialPeriod"
            id="booking-trialPeriod-duration"
            v-model="duration"
            searchable
            :options="availableTrialPeriodDurations"
            option-label="name"
            track-by="duration"
            label="Dauer Probestudium"
            :errors="errors"
            required
            wide
            mapped
          />
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          name="Erreichte ECTS Anzahl"
          rules="required|integer|min_value:0|max_value:15"
          slim
        >
          <e-text-field
            v-show="isGraduateTrialPeriod"
            id="trial-period-ects"
            v-model.number="ects"
            label="Erreichte ECTS Anzahl"
            :errors="errors"
            required
            disabled
            wide
          />
        </validation-provider>
      </div>
    </template>
    <template #submit-button-label> Ausführen </template>
  </process-modal-form>
</template>

<script>
import { ETextField, EMultiselect } from '@careerpartner/nitro';
import { ValidationProvider, extend } from 'vee-validate';
import { API_ENDPOINTS, DATE_FORMAT } from '@/common/constants';
import { required } from 'vee-validate/dist/rules';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { TRIAL_PERIOD_EVENTS } from '@/common/events/trialStudyEvents';
import { END_TRIAL_PERIOD_REASONS } from '@/common/selectLists/endTrialPeriodReasons';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';

extend('required', {
  ...required,
  message: 'Dieses Feld muss ausgefüllt sein.',
});

export default {
  name: 'EndTrialPeriodModal',
  components: {
    ProcessModalForm,
    ETextField,
    EMultiselect,
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    actionName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      studyStart: '',
      error: '',
      endDate: '',
      ects: 15,
      waitingForResponse: false,
      duration: 12,
    };
  },
  computed: {
    isAddTrialPeriod() {
      return this.actionName === TRIAL_PERIOD_EVENTS.ON_ADD_TRIAL;
    },
    isGraduateTrialPeriod() {
      return this.actionName === TRIAL_PERIOD_EVENTS.ON_GRADUATE_TRIAL;
    },
    isRevokeTrialPeriod() {
      return this.actionName === TRIAL_PERIOD_EVENTS.ON_REVOKE_TRIAL;
    },
    todayDate() {
      return dayjs().format(DATE_FORMAT);
    },
    availableTrialPeriodDurations() {
      return [
        {
          duration: 12,
          name: '12 Monate',
        },
        {
          duration: 18,
          name: '18 Monate',
        },
        {
          duration: 24,
          name: '24 Monate',
        },
      ];
    },
  },
  beforeMount() {
    this.setDates();
  },
  beforeDestroy() {
    this.error = '';
  },
  methods: {
    async submitRecall() {
      this.waitingForResponse = true;

      let recallUrl = API_ENDPOINTS.END_TRIAL_PERIOD.replace(
        ':bookingId',
        this.$route.params.bookingId,
      );

      let message = '';
      let endReason = '';
      let ects = 0;

      if (this.actionName === TRIAL_PERIOD_EVENTS.ON_GRADUATE_TRIAL) {
        message = 'Probestudium erfolgreich bestanden.';
        endReason = END_TRIAL_PERIOD_REASONS.GRADUATE_TRIAL;
        ects = 15;
      } else if (
        this.actionName === TRIAL_PERIOD_EVENTS.ON_END_TRIAL_WITH_DOCUMENTS
      ) {
        message = 'Probestudium erfolgreich beendet.';
        endReason = END_TRIAL_PERIOD_REASONS.END_TRIAL_WITH_DOCUMENTS;
      } else {
        message = 'Probestudium erfolgreich widerrufen.';
        endReason = END_TRIAL_PERIOD_REASONS.REVOKE_TRIAL;
      }

      let recallPayload = {
        endDate: this.endDate,
        ects,
        endReason,
      };

      // handle add trial part independently as this wasnt written with adding in mind
      if (this.actionName === TRIAL_PERIOD_EVENTS.ON_ADD_TRIAL) {
        message = 'Probestudium erfolgreich hinzugefügt.';
        recallUrl = API_ENDPOINTS.ADD_TRIAL_PERIOD.replace(
          ':bookingId',
          this.$route.params.bookingId,
        );

        recallPayload = {
          duration: this.duration,
        };
      }

      try {
        await ApiServiceWrapper.post(recallUrl, recallPayload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message,
        });

        this.$emit('end-trial-period-successful');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
    setDates() {
      this.studyStart = dayjs(this.booking.startDate)
        .format(DATE_FORMAT)
        .toString();
      this.endDate = dayjs().format(DATE_FORMAT).toString();
    },
  },
};
</script>
