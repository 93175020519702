<template>
  <e-button
    variant="text"
    small
    data-test="booking__add-admission"
    :disabled="disableAdmissionProgramButton || disabled"
    @click="handleAddClick"
  >
    <e-icon icon="wrench-outline" class="mr-1" size="xs" />Zulassung hinzufügen
  </e-button>
</template>

<script>
import { EButton, EIcon } from '@careerpartner/nitro';
import { ADMISSION_PROGRAM_EVENTS } from '@/common/events/admissionProgramEvents';
import { FEATURES, ENROLMENT_STATUS_STRINGS } from '@/common/constants';
import { mapGetters } from 'vuex';
import { Getters } from '@/store/modules/booking/types';

export default {
  name: 'AdmissionProgramButton',
  components: { EButton, EIcon },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('booking', {
      hasFailedAdmissionProgram: Getters.HAS_FAILED_ADMISSION_PROGRAM,
      bookingStatus: Getters.GET_CURRENT_BOOKING_STATUS,
    }),
    disableAdmissionProgramButton() {
      if (
        this.$isInactive(FEATURES.ADMISSION_PROGRAM) ||
        this.hasFailedAdmissionProgram ||
        this.isInStudies()
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    handleAddClick() {
      this.$emit('menuButtonClicked', ADMISSION_PROGRAM_EVENTS.ADD_ADMISSION);
    },
    isInStudies() {
      return this.bookingStatus?.name === ENROLMENT_STATUS_STRINGS.STUDYING;
    },
  },
};
</script>
