<template>
  <div v-if="loading" class="py-6 text-center">
    <p>Daten werden vorbereitet...</p>
  </div>
  <process-modal-form
    v-else
    :id="id"
    data-test="add-admission-modal"
    :invalid="invalidForm"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitForm"
  >
    <template #form-content>
      <div class="col-span-12">
        <validation-provider
          v-slot="{ errors }"
          name="program"
          rules="required"
          slim
        >
          <e-multiselect
            id="admissionCourseProgram"
            v-model="admission.programId"
            searchable
            :options="programs"
            option-label="displayName"
            track-by="id"
            label="Programmname"
            :errors="errors"
            :disabled="isExistingAdmission"
            required
            mapped
          />
        </validation-provider>
      </div>
      <div class="col-span-12">
        <validation-provider v-slot="{ errors }" rules="required" slim>
          <e-multiselect
            id="admissionCourseCurriculum"
            v-model="admission.curriculumVersionId"
            name="curriculum"
            :disabled="
              noProgramSelected || waitingForResponse || isExistingAdmission
            "
            :errors="errors"
            :options="curricula"
            label="Curriculum"
            mapped
            track-by="id"
            option-label="name"
            required
            searchable
          />
        </validation-provider>
      </div>
      <div class="grid grid-cols-12 gap-x-6 mt-6">
        <div class="col-span-6">
          <validation-provider
            v-slot="{ errors }"
            name="startDate"
            :rules="{
              required: true,
              date_format: true,
              after: { date: today, inclusive: true },
            }"
            slim
          >
            <e-text-field
              id="admissionCourseStartDate"
              v-model="admissionStartDate"
              :errors="errors"
              type="date"
              label="Startdatum"
              required
            />
          </validation-provider>
        </div>
        <div class="col-span-6">
          <e-text-field
            id="admissionCourseEndDate"
            v-model="admissionEndDate"
            label="Fristende"
            disabled
          />
        </div>
      </div>
    </template>

    {{ invalidForm }}
    <template #submit-button-label>Zulassung speichern</template>
  </process-modal-form>
</template>

<script>
import { EMultiselect, ETextField } from '@careerpartner/nitro';
import { ValidationProvider } from 'vee-validate';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import { ADMISSION_PROGRAM_STATUS, API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import ProcessModalForm from '../ProcessModals/shared/ProcessModalForm.vue';

export default {
  name: 'UpsertAdmissionsModal',
  components: {
    ETextField,
    EMultiselect,
    ValidationProvider,
    ProcessModalForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    admissionId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      error: '',
      loading: true,
      waitingForResponse: false,
      admission: {
        programId: null,
        curriculumVersionId: null,
        startDate: null,
        status: ADMISSION_PROGRAM_STATUS.IN_PROGRESS,
      },
      programs: [],
      curricula: [],
    };
  },
  computed: {
    bookingStartDate() {
      return this.booking.startDate;
    },
    admissionEndDate() {
      if (this.admissionStartDate) {
        return dayjs(this.admissionStartDate)
          .add(12, 'month')
          .format('DD.MM.YYYY');
      }

      return null;
    },
    invalidForm() {
      return this.invalid || this.waitingForResponse;
    },
    noProgramSelected() {
      return this.admission.programId == null;
    },
    today() {
      return dayjs().format('YYYY-MM-DD');
    },
    isExistingAdmission() {
      return this.admission.curriculum?.id != null;
    },
    admissionStartDate: {
      get() {
        return this.admission.startDate || this.bookingStartDate;
      },
      set(value) {
        this.admission.startDate = value;
      },
    },
  },
  watch: {
    admissionId: {
      async handler(newId) {
        if (newId !== '') {
          await this.loadAvailablePrograms();
          await this.loadAvailableCurricula();

          const admission =
            this.$store.getters[
              `booking/${BookingGetters.GET_ADMISSION_COURSE}`
            ](newId);

          this.admission = admission;
          this.admission.programId = admission.program.id;
          this.admission.curriculumVersionId = admission.curriculum?.id;
        }
      },
      immediate: true,
    },
    'admission.programId'(newId) {
      if (newId) {
        this.loadAvailableCurricula();
      }
    },
  },
  async beforeMount() {
    await this.loadAvailablePrograms();
  },
  methods: {
    // the method can be 'GET' | 'PATCH' | 'POST'
    async sendRequest(url, method, payload, message, loading = true) {
      try {
        this.loading = loading;
        this.waitingForResponse = true;
        let response;

        if (method === 'GET') {
          const { data } = await ApiServiceWrapper.get(url);
          response = data;
        }

        if (method === 'PATCH') {
          const { data } = await ApiServiceWrapper.patch(url, payload);
          response = data;

          this.$emit('update-successful');
        }

        if (method === 'POST') {
          const { data } = await ApiServiceWrapper.post(url, payload);
          response = data;

          this.$emit('update-successful');
        }

        if (message) {
          await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
            message,
          });
        }

        return response;
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
        this.loading = false;
      }
    },
    async loadAvailablePrograms() {
      const requestUrl = API_ENDPOINTS.AVAILABLE_ADMISSION_PROGRAMS;
      const programs = await this.sendRequest(requestUrl, 'GET');

      this.programs = this.sortByShortNameSimilarityAndStudyStart(
        programs.map((program) => ({
          ...program,
          ...program.program,
          displayName: program.program.shortName
            ? `${program.program.shortName} - ${program.program.title}`
            : program.program.title,
        })),
        this.booking.program.shortName,
      );
    },

    sortByShortNameSimilarityAndStudyStart(curricula, shortName) {
      return curricula.sort((a, b) => {
        const similarityScoreA = this.calculateSimilarityScore(
          a.shortName || '',
          shortName || '',
        );
        const similarityScoreB = this.calculateSimilarityScore(
          b.shortName || '',
          shortName || '',
        );

        return similarityScoreB - similarityScoreA;
      });
    },

    calculateSimilarityScore(programShortName, bookingProgramShortName) {
      // Calculate the similarity score between 'programShortName' and 'bookingProgramShortName'.
      // You can use various similarity metrics like Levenshtein distance,
      // Jaccard similarity, or any other custom logic based on your requirements.
      // For simplicity, this example uses Jaccard similarity:
      const setA = new Set(programShortName.split(''));
      const setB = new Set(bookingProgramShortName.split(''));

      const intersection = new Set([...setA].filter((x) => setB.has(x)));
      const union = new Set([...setA, ...setB]);

      return intersection.size / union.size;
    },

    async loadAvailableCurricula() {
      const requestUrl = API_ENDPOINTS.PROGRAM_AVAILABLE_CURRICULA.replace(
        ':careProgramId',
        this.admission.programId,
      );

      const curricula = await this.sendRequest(
        requestUrl,
        'GET',
        null,
        null,
        false,
      );

      this.curricula = this.sortByShortNameSimilarityAndStudyStart(
        curricula,
        this.booking.program.shortName,
      ).map((curriculum) => ({
        ...curriculum,
        name: curriculum.shortName
          ? `${curriculum.shortName} - ${curriculum.name}`
          : curriculum.name,
      }));
    },
    async updateAdmissionProgram() {
      const requestUrl = API_ENDPOINTS.PATCH_ADMISSION.replace(
        ':bookingId',
        this.booking.id,
      ).replace(':admissionId', this.admissionId);

      const payload = {
        startDate: this.admissionStartDate,
      };

      if (!this.admission.curriculum?.id) {
        payload.curriculum = this.admission.curriculumVersionId;
      }

      await this.sendRequest(
        requestUrl,
        'PATCH',
        payload,
        'Zulassungsprogramm wurde erfolgreich aktualisiert',
      );
    },
    async submitForm() {
      if (this.admission.id) {
        await this.updateAdmissionProgram();
        return;
      }

      const payload = {
        ...this.admission,
        startDate: this.admissionStartDate,
      };

      const requestUrl = API_ENDPOINTS.ADD_ADMISSION.replace(
        ':bookingId',
        this.booking.id,
      );

      await this.sendRequest(
        requestUrl,
        'POST',
        payload,
        'Einschreibung in Zulassungsprogramm(e) war erfolgreich',
      );
    },
  },
};
</script>
