<template>
  <process-modal-form
    :id="id"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitRevocation"
  >
    <template #form-content>
      <div v-if="isRevocationDateOver" class="banner">
        <e-icon icon="information" size="xl" /> Widerrufsfrist ist bereits
        abgelaufen.
      </div>
      <div class="flex flex-col space-y-6">
        <validation-provider
          v-slot="{ errors }"
          name="Kategorie des Kündigungsgrundes"
          :rules="{ required: true }"
          slim
          immediate
        >
          <e-multiselect
            id="revocation-categories"
            v-model="revocationCategory"
            searchable
            :options="availableRevocationCategories"
            option-label="name"
            track-by="key"
            label="Kategorie des Widerrufsgrundes"
            :errors="errors"
            required
            mapped
          />
        </validation-provider>

        <div v-if="revocationCategory !== 'other'">
          <validation-provider
            v-slot="{ errors }"
            name="Beschreibung des Kündigungsgrundes"
            :rules="{
              required: !!revocationCategory,
            }"
            slim
            immediate
          >
            <e-multiselect
              id="revocation-description"
              v-model="revocationDescription"
              searchable
              :options="availableRevocationDescriptions"
              option-label="name"
              track-by="name"
              label="Beschreibung des Kündigungsgrundes"
              :errors="errors"
              required
              mapped
              :disabled="!revocationCategory || revocationCategory.length === 0"
            />
          </validation-provider>
        </div>

        <div v-else>
          <validation-provider
            v-slot="{ errors }"
            name="Beschreibung des Widerrufsgrundes"
            rules="max:200"
          >
            <e-text-field
              id="cancellation-manualEntry"
              v-model="revocationDescription"
              label="Beschreibung des Widerrufsgrundes"
              :errors="errors"
            />
          </validation-provider>
        </div>
      </div>

      <!-- Dates -->
      <div class="flex space-x-4">
        <validation-provider
          v-slot="{ errors }"
          name="Datum der Kündigung"
          :rules="{
            required: !!revocationCategory,
            date_format: true,
            after: { date: revocationDateMin, inclusive: true },
            before: { date: revocationDateMax, inclusive: true },
          }"
          slim
          immediate
        >
          <e-text-field
            id="booking-cancellation-cancellationDate"
            v-model="revocationDate"
            type="date"
            label="Datum der Kündigung"
            wide
            :errors="errors"
            required
            :disabled="!revocationCategory || revocationCategory.length === 0"
          />
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="Datum Studienende"
          :rules="{
            required: !!revocationCategory,
            date_format: true,
            after: { date: contractEndDateMin, inclusive: true },
            before: { date: contractEndDateMax, inclusive: true },
          }"
          slim
          immediate
        >
          <e-text-field
            id="booking-cancellation-contractEnd"
            v-model="contractEndDate"
            type="date"
            label="Datum Studienende"
            wide
            :errors="errors"
            required
            :disabled="!revocationCategory || revocationCategory.length === 0"
          />
        </validation-provider>
      </div>
    </template>
    <template #submit-button-label>Vertrag widerrufen</template>
  </process-modal-form>
</template>

<script>
import { EMultiselect, ETextField, EIcon } from '@careerpartner/nitro';
import { extend, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { REVOCATION_CATEGORIES } from '@/common/selectLists/revocationCategories';
import {
  API_ENDPOINTS,
  DATE_FORMAT,
  ENROLMENT_STATUS_STRINGS,
} from '@/common/constants';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { CANCEL_REASON_KEYS } from '@/common/selectLists/cancellationReasons';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';

extend('required', {
  ...required,
  message: 'Dieses Feld muss ausgefüllt sein.',
});

export default {
  name: 'RevocationModal',
  components: {
    EMultiselect,
    ETextField,
    EIcon,
    ValidationProvider,
    ProcessModalForm,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      revocationCategory: '',
      revocationDescription: '',
      revocationDate: null,
      revocationDateMin: '',
      revocationDateMax: '',
      contractEndDate: null,
      contractEndDateMin: '',
      contractEndDateMax: '',
      waitingForResponse: false,
      isRevocationDateOver: false,
    };
  },
  computed: {
    availableRevocationCategories() {
      return REVOCATION_CATEGORIES;
    },
    availableRevocationDescriptions() {
      const descriptions = (categoryKey) =>
        REVOCATION_CATEGORIES.find((category) => category.key === categoryKey)
          .descriptions;

      return this.revocationCategory && this.revocationCategory.length
        ? descriptions(this.revocationCategory)
        : [];
    },
  },
  watch: {
    revocationCategory() {
      this.revocationDescription = '';
      this.setRevocationDate();
      this.setContractEndDate();
    },
  },
  beforeMount() {
    this.isRevocationDateOver = dayjs(
      this.booking.status?.revocationDeadline,
    ).isBefore(dayjs());
    this.setRevocationDate();
    this.setContractEndDate();
  },
  methods: {
    setContractEndDate() {
      if (
        this.booking.status.name === ENROLMENT_STATUS_STRINGS.SIGNED ||
        this.booking.status.name === ENROLMENT_STATUS_STRINGS.IN_ADMISSION
      ) {
        this.contractEndDate = dayjs().format(DATE_FORMAT);
      } else {
        this.contractEndDate =
          this.booking.status?.revocationDeadline ||
          dayjs(this.booking.endDate).add(1, 'month').format(DATE_FORMAT);
      }
      this.contractEndDateMin = dayjs(this.booking.startDate)
        .subtract(6, 'month')
        .format(DATE_FORMAT);

      this.contractEndDateMax = dayjs(this.booking.endDate)
        .add(1, 'month')
        .format(DATE_FORMAT);
    },
    setRevocationDate() {
      this.revocationDate = dayjs().format(DATE_FORMAT);
      this.revocationDateMin = dayjs(this.booking.startDate)
        .subtract(6, 'month')
        .format(DATE_FORMAT);
      this.revocationDateMax = dayjs(new Date())
        .add(1, 'month')
        .format(DATE_FORMAT);
    },
    async submitRevocation() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.CANCELLATION.replace(
        ':bookingId',
        this.$route.params.bookingId,
      );

      const payload = {
        cancellationDate: dayjs(this.revocationDate).format(DATE_FORMAT),
        contractEnd: dayjs(this.contractEndDate).format(DATE_FORMAT),
        reason: CANCEL_REASON_KEYS.REVOCATION,
        category:
          this.revocationCategory === '' ? null : this.revocationCategory,
        description: this.revocationDescription,
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Buchung erfolgreich beendet.',
        });

        this.$emit('revocation-successful');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>

<style>
.banner {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: white;
  background-color: rgb(59, 130, 246);
  padding: 0px 10px 0px 10px;
}
</style>
