import { Mutations } from './types';

export default {
  [Mutations.INCREMENT_LOADING](state) {
    state.loadingCounter++;
  },
  [Mutations.DECREMENT_LOADING](state) {
    if (state.loadingCounter <= 0) {
      state.loadingCounter = 0;
      return;
    }

    state.loadingCounter--;
  },
  [Mutations.UPDATE_SNACKBAR](state, snackbar) {
    state.snackbar = snackbar;
  },
  [Mutations.CLEAR_ERRORS](state) {
    state.errors = [];
  },
  [Mutations.PUSH_TO_ERROR_STACK](state, payload) {
    state.errors.push(payload);
  },
  [Mutations.REMOVE_ERROR_FROM_STACK](state) {
    state.errors.shift();
  },
};
