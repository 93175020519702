<template>
  <div>
    <status-chip
      v-if="status && status.name"
      :content="ENROLMENT_STATUS[status.name] || '-'"
      :variant="status.name"
    />
    <dl
      class="flex flex-col text-gray-700"
      :class="outlined ? ['p-6', 'pb-8'] : ['mt-5', 'mb-8']"
    >
      <definition-item
        v-if="status.matriculationDate"
        :column="false"
        :headline-bold="false"
        headline="immatrikuliert am"
        :content="formatDate(status.matriculationDate)"
      />
      <definition-item
        v-if="status.exmatriculationDate"
        :column="false"
        :headline-bold="false"
        headline="graduiert am"
        :content="formatDate(status.exmatriculationDate)"
      />
      <definition-item
        v-if="showRevocationDeadline"
        :column="false"
        :headline-bold="false"
        headline="Widerrufsfrist bis"
        :content="formatDate(status.revocationDeadline)"
      />
      <div v-if="conditions && conditions.length > 0">
        <ul>
          <li v-for="condition in conditions" :key="condition.id">
            <booking-condition
              v-if="!condition.resolved"
              :value="condition"
              class="flex items-center justify-between gap-4"
            />
          </li>
        </ul>
      </div>
      <interrupted-status
        v-if="isInterrupted"
        :content="interruptionDatesString"
        :reason="getInterruptionReason"
      />
      <definition-item
        v-if="showQualificationNotNotarised"
        :column="false"
        :headline-bold="false"
        headline="beglaubigte HZB ausstehend"
        content=""
      />
      <div v-if="trialPeriodPending">
        <definition-item
          :column="false"
          :headline-bold="false"
          headline="Probestudium ausstehend"
          content=""
          ><e-icon
            icon="timer-settings-outline"
            class="border-green-600 border-l-4 px-2"
          ></e-icon>
        </definition-item>
      </div>
      <div v-else-if="trialPeriodActive">
        <definition-item
          class="border-green-600 border-l-4 px-2"
          :column="false"
          :headline-bold="false"
          headline="Probestudium bis "
          :content="formatDate(trialPeriodEndDate)"
        />
      </div>
      <div v-if="showExmatriculationDate">
        <definition-item
          :headline-bold="false"
          :headline="headlineReason"
          :content="exmatriculationDate"
        />
        <definition-item
          class="pt-3"
          content=""
          :headline-bold="false"
          :headline="displayReason"
          :column="false"
        >
          <e-icon
            icon="bank-off-outline"
            class="border-pink-600 text-pink-600 border-l-4 px-2"
          ></e-icon>
        </definition-item>
      </div>
    </dl>
  </div>
</template>

<script>
import StatusChip from '@/components/shared/Status/StatusChip';
import DefinitionItem from '@/components/shared/DefinitionItem';
import formatHelper from '@/mixins/formatHelper';
import {
  FEATURES,
  ENROLMENT_STATUS,
  ENROLMENT_STATUS_STRINGS,
  STATUS_CONDITIONS_STRINGS,
  STATUS_HEADLINE,
} from '@/common/constants';
import { EIcon } from '@careerpartner/nitro';
import dayjs from 'dayjs';
import {
  getCancellationReasons,
  CANCEL_REASON_KEYS,
} from '@/common/selectLists/cancellationReasons';
import { INTERRUPTION_REASONS } from '@/common/selectLists/interruptions';
import BookingCondition from '@/components/BookingCondition';
import InterruptedStatus from '../InterruptedStatus.vue';
import { isInactive } from '@/utils/feature-config';

export default {
  name: 'Status',
  components: {
    DefinitionItem,
    EIcon,
    StatusChip,
    InterruptedStatus,
    BookingCondition,
  },
  mixins: [formatHelper],
  props: {
    cancellation: {
      type: Object,
      default: () => ({}),
    },
    graduationDetails: {
      type: Object,
      default: () => ({}),
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: '',
    },
    status: {
      type: Object,
      required: true,
    },
    conditions: {
      type: Array,
      required: false,
      default: () => [],
    },
    trialPeriod: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    isInterrupted() {
      return this.status.name === ENROLMENT_STATUS_STRINGS.INTERRUPTED;
    },
    getInterruptionReason() {
      return INTERRUPTION_REASONS.find(
        (item) => item.key === this.status.interruptionReason,
      )?.label;
    },
    displayReason() {
      let reason = getCancellationReasons().find(
        (reason) => reason.key === this.cancellation?.reason,
      );

      if (!reason && this.graduationDetails?.exmatriculationDate) {
        reason = getCancellationReasons().find(
          (reason) => reason.key === CANCEL_REASON_KEYS.GRADUATION,
        );
      }

      return reason?.name || 'nicht definiert';
    },
    headlineReason() {
      if (this.graduationDetails?.exmatriculationDate) {
        return STATUS_HEADLINE.EXMATRICULATED;
      }

      return '';
    },
    interruptionDatesString() {
      return `${this.formatDate(
        this.status.interruptionStartDate,
      )} - ${this.formatDate(this.status.interruptionEndDate)}\n`;
    },
    ENROLMENT_STATUS() {
      return ENROLMENT_STATUS;
    },
    ENROLMENT_STATUS_STRINGS() {
      return ENROLMENT_STATUS_STRINGS;
    },
    trialPeriodPending() {
      return this.trialPeriod != null && dayjs(this.startDate).isAfter(dayjs());
    },
    trialPeriodActive() {
      if (this.trialPeriod === null) {
        return false;
      }

      return (
        this.trialPeriod.passed === null &&
        dayjs(this.startDate).isBefore(dayjs())
      );
    },
    trialPeriodEndDate() {
      return dayjs(this.startDate).add(this.trialPeriod.duration, 'months');
    },
    showRevocationDeadline() {
      return this.status.revocationDeadline !== undefined;
    },
    showQualificationNotNotarised() {
      if (isInactive(FEATURES.CLICK_AND_STUDY)) {
        return;
      }
      return this.status.conditions?.includes(
        STATUS_CONDITIONS_STRINGS.QUALIFICATION_NOT_NOTARISED,
      );
    },
    showExmatriculationDate() {
      if (this.status.name === ENROLMENT_STATUS_STRINGS.ENDED) {
        return true;
      }

      if (this.status.name === ENROLMENT_STATUS_STRINGS.GRADUATED) {
        return true;
      }

      if (this.cancellation?.contractEnd) {
        return true;
      }

      return !!this.graduationDetails?.exmatriculationDate;
    },
    exmatriculationDate() {
      if (this.graduationDetails?.exmatriculationDate) {
        return this.formatDate(this.graduationDetails.exmatriculationDate);
      }

      return '';
    },
  },
};
</script>
