<template>
  <form
    class="flex flex-col px-7 pt-6 pb-8 space-y-6 h-full"
    :data-test="`${id}-form`"
    @submit.prevent
  >
    <div class="flex flex-col flex-grow px-0 space-y-6">
      <slot name="form-content" />
      <p class="mt-8 text-xs text-gray-700">* Pflichtfelder</p>
    </div>

    <!-- Error message -->
    <div v-show="error.length" class="py-2 text-error">
      <p>
        <e-icon icon="mdi-alert-outline" class="mr-2" />
        {{ readableErrorMessage }}
      </p>
    </div>

    <div class="mt-2 col-span-full self-end">
      <slot name="actions"></slot>
    </div>

    <!-- Submit Button -->
    <div class="flex flex-col mt-16 col-span-full min-h-9">
      <e-button
        variant="primary"
        :disabled="invalid || waitingForResponse"
        class="ml-auto mt-18"
        :data-test="`${id}-submit-button`"
        @click.stop="$emit('submit')"
      >
        <e-icon
          v-if="waitingForResponse"
          class="mr-2 animate-spin"
          icon="mdi-loading"
        />
        <slot name="submit-button-label" />
      </e-button>

      <div class="mt-2 col-span-full self-end">
        <slot name="additional-content" />
      </div>
    </div>
  </form>
</template>

<script>
import { EButton, EIcon } from '@careerpartner/nitro';

export default {
  name: 'ProcessModalForm',
  components: {
    EButton,
    EIcon,
  },
  props: {
    error: {
      type: [Object, String],
      default: () => {},
    },
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    waitingForResponse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    readableErrorMessage() {
      return this.error;
    },
  },
};
</script>
