<template>
  <div>
    <process-modal-form
      id="profile-accomodation-form"
      :error="error"
      :invalid="invalid"
      :waiting-for-response="waitingForResponse"
      @submit="submitForm"
    >
      <template #form-content>
        <validation-provider v-slot="{ errors }" name="extendExamTime">
          <e-radio-group
            v-model="extendExamTime"
            :options="extendOptions"
            track-by="key"
            label="Verlängerung der Prüfungszeit?"
            :errors="errors"
            name="extend-exam-time"
          />
        </validation-provider>

        <div v-show="shouldExtendExamTime">
          <validation-provider
            v-slot="{ errors }"
            vid="examTimeExtension"
            name="Prüfungszeitverlängerung"
            rules="required|integer|max_value:100|not_both_0:@studyTimeExtension"
          >
            <e-text-field
              id="booking-previous-related-terms"
              v-model="payload.examTimeExtension"
              label="Prüfungszeitverlängerung in Prozent"
              :errors="errors"
              required
            />
          </validation-provider>

          <div class="grid grid-cols-12 gap-x-6 mt-6">
            <div class="col-span-6">
              <validation-provider
                v-slot="{ errors }"
                name="Zeitliche Begrenzung"
                rules="required"
              >
                <e-multiselect
                  id="examTimeExtensionOption"
                  v-model="examTimeExtensionOption"
                  searchable
                  :options="extendExamTimeOptions"
                  option-label="label"
                  track-by="key"
                  label="Zeitliche Begrenzung"
                  :errors="errors"
                  required
                  mapped
                />
              </validation-provider>
            </div>

            <div v-if="shouldSetValidUntil" class="col-span-6">
              <validation-provider
                v-slot="{ errors }"
                name="Ende Nachteilsausgleich"
                :rules="{
                  required: true,
                  date_format: true,
                  after: { date: today, inclusive: true },
                }"
              >
                <e-text-field
                  id="validUntil"
                  v-model="payload.validUntil"
                  type="date"
                  :errors="errors"
                  label="Ende Nachteilsausgleich"
                  required
                />
              </validation-provider>
            </div>
          </div>
        </div>

        <e-radio-group
          v-model="extendStudyTime"
          :options="extendOptions"
          track-by="key"
          label="Erweiterung der kostenlosen Verlängerung der Studienlaufzeit:"
          name="study-time"
        />

        <div v-show="shouldExtendStudyTime">
          <validation-provider
            v-slot="{ errors }"
            vid="studyTimeExtension"
            name="Erweiterung"
            rules="required|integer|max_value:100|not_both_0:@examTimeExtension"
          >
            <e-text-field
              id="extend-study-time-percentage"
              v-model="payload.studyTimeExtension"
              label="Erweiterung in Prozent"
              :errors="errors"
              required
            />
          </validation-provider>
        </div>
      </template>
      <template #submit-button-label>Speichern</template>
      <template #additional-content>
        <p class="text-gray-700 text-xs">
          Für <span class="font-bold">alle</span> Buchungen gültig.
        </p>
      </template>
    </process-modal-form>
  </div>
</template>

<script>
import {
  Actions as ProfileActions,
  Getters as ProfileGetters,
} from '@/store/modules/profile/types';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { ERadioGroup, EMultiselect, ETextField } from '@careerpartner/nitro';
import { ValidationProvider, extend } from 'vee-validate';
import dayjs from 'dayjs';
import { max_value } from 'vee-validate/dist/rules';
import { getApiError } from '@/utils/ApiServiceWrapper';

extend('max_value', {
  ...max_value,
  message: '{_field_} darf maximal {max} sein',
});

extend('not_both_0', {
  params: ['otherValue'],
  validate: (value, { otherValue }) =>
    !(value === 0 && parseInt(otherValue) === 0),
  message:
    'Erweiterung der kostenlosen Verlängerung der Studienlaufzeit und Prüfungszeitverlängerung dürfen nicht gleichzeitig 0 sein.',
  castValue: (value) => parseInt(value),
});

export default {
  name: 'ProfileAccomodationProcessModal',
  components: {
    ValidationProvider,
    ERadioGroup,
    EMultiselect,
    ETextField,
    ProcessModalForm,
  },
  props: {
    invalid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      error: '',
      waitingForResponse: false,
      extendExamTime: true,
      extendStudyTime: true,
      examTimeExtensionOption: 'unlimited',
      payload: {
        studyTimeExtension: null,
        examTimeExtension: null,
        validUntil: null,
      },
    };
  },
  computed: {
    profileAccomodationStoreData() {
      return JSON.parse(
        JSON.stringify(
          this.$store.getters[`profile/${ProfileGetters.PROFILE_ACCOMODATION}`],
        ),
      );
    },
    showModal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    today() {
      return dayjs().format('YYYY-MM-DD');
    },
    shouldExtendExamTime() {
      return this.extendExamTime === true;
    },
    shouldSetValidUntil() {
      return this.examTimeExtensionOption === 'limited';
    },
    shouldExtendStudyTime() {
      return this.extendStudyTime === true;
    },
    extendOptions() {
      return [
        { label: 'Ja', key: true },
        { label: 'Nein', key: false },
      ];
    },
    extendExamTimeOptions() {
      return [
        { label: 'unbegrenzt', key: 'unlimited' },
        { label: 'begrenzt', key: 'limited' },
      ];
    },
  },
  watch: {
    extendExamTime(value) {
      if (!value) {
        this.payload.examTimeExtension = 0;
      }
    },
    extendStudyTime(value) {
      if (!value) {
        this.payload.studyTimeExtension = 0;
      }
    },
  },
  created() {
    const dataInStore = this.profileAccomodationStoreData;
    if (!dataInStore) {
      return;
    }

    this.payload = dataInStore;

    if (this.payload.examTimeExtension > 0) {
      this.extendExamTime = true;
    } else {
      this.extendExamTime = false;
    }

    if (this.payload.studyTimeExtension > 0) {
      this.extendStudyTime = true;
    } else {
      this.extendStudyTime = false;
    }

    this.examTimeExtensionOption = this.payload.validUntil
      ? 'limited'
      : 'unlimited';
  },
  methods: {
    async submitForm() {
      this.waitingForResponse = true;

      const payload = {
        profileId: this.$route.params.id,
        requestPayload: {
          studyTimeExtension: parseInt(this.payload.studyTimeExtension),
          examTimeExtension: parseInt(this.payload.examTimeExtension),
          validUntil: this.payload.validUntil,
        },
      };

      if (!this.shouldSetValidUntil) {
        payload.requestPayload.validUntil = null;
      }

      if (!this.shouldExtendStudyTime) {
        payload.requestPayload.studyTimeExtension = 0;
      }

      if (!this.shouldExtendExamTime) {
        payload.requestPayload.examTimeExtension = 0;
      }

      try {
        await this.$store.dispatch(
          `profile/${ProfileActions.SET_PROFILE_ACCOMODATION}`,
          payload,
        );

        this.$emit('set-profile-accomodation');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
