import {
  cancelCategoryList,
  CANCEL_REASON_KEYS,
} from '@/common/selectLists/cancellationReasons';

export default function (reason) {
  let categories = [];

  if (!reason) return categories;

  if (reason === CANCEL_REASON_KEYS.REGULAR) {
    categories = cancelCategoryList
      .filter((category) =>
        [
          'private_reasons',
          'unsatisfied',
          'unsuitable_course',
          'other',
        ].includes(category.key),
      )
      .map((category) => ({ key: category.key, name: category.name }));
  }

  if (reason === CANCEL_REASON_KEYS.EXTRAORDINARY) {
    categories = cancelCategoryList
      .filter((category) => category.key === CANCEL_REASON_KEYS.EXTRAORDINARY)
      .map((category) => ({ key: category.key, name: category.name }));
  }

  return categories;
}
