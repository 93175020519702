var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('process-modal-form',{attrs:{"id":"profile-accomodation-form","error":_vm.error,"invalid":_vm.invalid,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitForm},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('validation-provider',{attrs:{"name":"extendExamTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-radio-group',{attrs:{"options":_vm.extendOptions,"track-by":"key","label":"Verlängerung der Prüfungszeit?","errors":errors,"name":"extend-exam-time"},model:{value:(_vm.extendExamTime),callback:function ($$v) {_vm.extendExamTime=$$v},expression:"extendExamTime"}})]}}])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldExtendExamTime),expression:"shouldExtendExamTime"}]},[_c('validation-provider',{attrs:{"vid":"examTimeExtension","name":"Prüfungszeitverlängerung","rules":"required|integer|max_value:100|not_both_0:@studyTimeExtension"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"booking-previous-related-terms","label":"Prüfungszeitverlängerung in Prozent","errors":errors,"required":""},model:{value:(_vm.payload.examTimeExtension),callback:function ($$v) {_vm.$set(_vm.payload, "examTimeExtension", $$v)},expression:"payload.examTimeExtension"}})]}}])}),_c('div',{staticClass:"grid grid-cols-12 gap-x-6 mt-6"},[_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Zeitliche Begrenzung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"examTimeExtensionOption","searchable":"","options":_vm.extendExamTimeOptions,"option-label":"label","track-by":"key","label":"Zeitliche Begrenzung","errors":errors,"required":"","mapped":""},model:{value:(_vm.examTimeExtensionOption),callback:function ($$v) {_vm.examTimeExtensionOption=$$v},expression:"examTimeExtensionOption"}})]}}])})],1),(_vm.shouldSetValidUntil)?_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Ende Nachteilsausgleich","rules":{
                required: true,
                date_format: true,
                after: { date: _vm.today, inclusive: true },
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"validUntil","type":"date","errors":errors,"label":"Ende Nachteilsausgleich","required":""},model:{value:(_vm.payload.validUntil),callback:function ($$v) {_vm.$set(_vm.payload, "validUntil", $$v)},expression:"payload.validUntil"}})]}}],null,false,2039066912)})],1):_vm._e()])],1),_c('e-radio-group',{attrs:{"options":_vm.extendOptions,"track-by":"key","label":"Erweiterung der kostenlosen Verlängerung der Studienlaufzeit:","name":"study-time"},model:{value:(_vm.extendStudyTime),callback:function ($$v) {_vm.extendStudyTime=$$v},expression:"extendStudyTime"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldExtendStudyTime),expression:"shouldExtendStudyTime"}]},[_c('validation-provider',{attrs:{"vid":"studyTimeExtension","name":"Erweiterung","rules":"required|integer|max_value:100|not_both_0:@examTimeExtension"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"extend-study-time-percentage","label":"Erweiterung in Prozent","errors":errors,"required":""},model:{value:(_vm.payload.studyTimeExtension),callback:function ($$v) {_vm.$set(_vm.payload, "studyTimeExtension", $$v)},expression:"payload.studyTimeExtension"}})]}}])})],1)]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Speichern")]},proxy:true},{key:"additional-content",fn:function(){return [_c('p',{staticClass:"text-gray-700 text-xs"},[_vm._v(" Für "),_c('span',{staticClass:"font-bold"},[_vm._v("alle")]),_vm._v(" Buchungen gültig. ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }