var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"py-6 text-center"},[_c('p',[_vm._v("Daten werden vorbereitet...")])]):_c('process-modal-form',{attrs:{"id":_vm.id,"data-test":"add-admission-modal","invalid":_vm.invalidForm,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitForm},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',{staticClass:"col-span-12"},[_c('validation-provider',{attrs:{"name":"program","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"admissionCourseProgram","searchable":"","options":_vm.programs,"option-label":"displayName","track-by":"id","label":"Programmname","errors":errors,"disabled":_vm.isExistingAdmission,"required":"","mapped":""},model:{value:(_vm.admission.programId),callback:function ($$v) {_vm.$set(_vm.admission, "programId", $$v)},expression:"admission.programId"}})]}}])})],1),_c('div',{staticClass:"col-span-12"},[_c('validation-provider',{attrs:{"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"admissionCourseCurriculum","name":"curriculum","disabled":_vm.noProgramSelected || _vm.waitingForResponse || _vm.isExistingAdmission,"errors":errors,"options":_vm.curricula,"label":"Curriculum","mapped":"","track-by":"id","option-label":"name","required":"","searchable":""},model:{value:(_vm.admission.curriculumVersionId),callback:function ($$v) {_vm.$set(_vm.admission, "curriculumVersionId", $$v)},expression:"admission.curriculumVersionId"}})]}}])})],1),_c('div',{staticClass:"grid grid-cols-12 gap-x-6 mt-6"},[_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"startDate","rules":{
            required: true,
            date_format: true,
            after: { date: _vm.today, inclusive: true },
          },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"admissionCourseStartDate","errors":errors,"type":"date","label":"Startdatum","required":""},model:{value:(_vm.admissionStartDate),callback:function ($$v) {_vm.admissionStartDate=$$v},expression:"admissionStartDate"}})]}}])})],1),_c('div',{staticClass:"col-span-6"},[_c('e-text-field',{attrs:{"id":"admissionCourseEndDate","label":"Fristende","disabled":""},model:{value:(_vm.admissionEndDate),callback:function ($$v) {_vm.admissionEndDate=$$v},expression:"admissionEndDate"}})],1)])]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Zulassung speichern")]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.invalidForm)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }