<template>
  <Loader v-if="loading" />
  <process-modal-form
    v-else
    :id="id"
    class="h-full overflow-y-auto"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="onSubmit"
  >
    <template #form-content>
      <e-section headline="Art des Dokuments">
        <validation-provider
          v-if="templates"
          v-slot="{ errors }"
          name="Dokumenten Art"
          rules="required"
          slim
        >
          <e-multiselect
            id="template"
            v-model="selectedTemplateId"
            :errors="errors"
            searchable
            label="Dokumententitel"
            :options="templateOptions"
            option-label="label"
            track-by="id"
            mapped
            required
          />
        </validation-provider>
      </e-section>

      <e-section
        v-if="userBindings.length > 0"
        headline="Benutzerdefinierte Inhalte"
      >
        <document-bindings v-model="userParameters" :bindings="userBindings" />
      </e-section>
    </template>
    <template #actions>
      <e-tooltip :content="!hasNeededAddressData ? 'Fehlende Profildaten' : ''">
        <e-button
          variant="blank"
          class="underline text-cta font-normal"
          :disabled="!hasNeededAddressData"
          @click="copyAddressToClipboard()"
        >
          Adresse als Tabellenzeile
        </e-button>
      </e-tooltip>
    </template>
    <template #submit-button-label> PDF Download </template>
  </process-modal-form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { EMultiselect, EButton, ETooltip } from '@careerpartner/nitro';
import ESection from '@/components/shared/ESection';
import Loader from '@/components/shared/Loader';

import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { Actions as AppActions } from '@/store/modules/app/types';
import DocumentBindings from '@/components/Document/Bindings';
import {
  Actions as DocumentActions,
  Getters as DocumentGetters,
} from '@/store/modules/document/types';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import { Getters as CurrentUserGetter } from '@/store/modules/currentUser';
import { getApiError } from '@/utils/ApiServiceWrapper';
import Vue from 'vue';

import { VTooltip, VPopover, VClosePopover } from 'v-tooltip';

Vue.directive('tooltip', VTooltip);
Vue.directive('close-popover', VClosePopover);
Vue.component('VPopover', VPopover);

export default {
  name: 'DocumentDownloadModal',
  components: {
    ProcessModalForm,
    ValidationProvider,
    EMultiselect,
    ESection,
    DocumentBindings,
    Loader,
    EButton,
    ETooltip,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: '',
      waitingForResponse: false,
      ready: false,
      userParameters: {},
    };
  },

  computed: {
    ...mapGetters('document', {
      templates: DocumentGetters.TEMPLATES,
      bindings: DocumentGetters.BINDINGS,
    }),
    ...mapFields('document', ['selectedTemplateId', 'parameters']),

    ...mapGetters('profile', {
      academyId: ProfileGetters.PROFILE_ACADEMY_ID,
      profile: ProfileGetters.PROFILE,
    }),
    ...mapGetters('booking', {
      bookingId: BookingGetters.GET_CURRENT_BOOKING_ID,
    }),
    ...mapGetters('currentUser', {
      currentUserAcademyId: CurrentUserGetter.ACADEMY_ID,
    }),

    templateOptions() {
      const createLabel = (t) => {
        const parts = [];
        parts.push(t.documentTemplate?.name ?? '<kein Name>');
        if (t.description) {
          parts.push(t.description);
        }
        parts.push(`V${t.version}`);

        return parts.join(' - ');
      };
      return (
        this.templates?.map((t) => ({
          id: t.id,
          label: createLabel(t),
        })) ?? []
      );
    },

    filledParameters() {
      return {
        academicProfile: { profileId: this.academyId },
        currentUserProfile: { profileId: this.currentUserAcademyId },
        booking: { bookingId: this.bookingId },
      };
    },
    userBindings() {
      const filledParameterNames = Object.keys(this.filledParameters);
      return this.bindings.filter(
        (b) => !filledParameterNames.includes(b.bindingName),
      );
    },
    hasNeededAddressData() {
      return (
        this.profile?.person?.givenName &&
        this.profile?.person?.familyName &&
        this.profile?.zip &&
        this.profile?.street &&
        this.profile?.city &&
        this.profile?.country &&
        this.profile?.email
      );
    },
  },

  watch: {
    selectedTemplateId(template) {
      try {
        this.loading = true;
        return this.fetchBindings(template);
      } catch (error) {
        this.error = getApiError(error);
      } finally {
        this.loading = false;
      }
    },
    bindings(bindings) {
      this.bindingValues = new Array(bindings.length);
      this.updateParameters();
    },
    userParameters() {
      this.updateParameters();
    },
    filledParameters() {
      this.updateParameters();
    },
  },

  async beforeMount() {
    try {
      this.selectedTemplate = null;
      await this.fetchTemplates();
    } catch (error) {
      this.error = getApiError(error);
    }
  },

  methods: {
    ...mapActions('document', {
      fetchTemplates: DocumentActions.FETCH_TEMPLATES,
      fetchBindings: DocumentActions.FETCH_BINDINGS,
      generateDocument: DocumentActions.GENERATE_DOCUMENT,
    }),

    updateParameters() {
      this.parameters = {
        ...this.userParameters,
        ...this.filledParameters,
      };
    },

    async onSubmit() {
      try {
        this.waitingForResponse = true;
        const result = await this.generateDocument();

        const link = document.createElement('a');
        link.href = URL.createObjectURL(result.file);
        link.download = result.filename;
        link.click();

        this.$emit('update-successful');
      } catch (error) {
        this.error = getApiError(error);
        throw error;
      } finally {
        this.waitingForResponse = false;
      }
    },

    copyAddressToClipboard() {
      const { givenName, familyName } = this.profile?.person;
      const { addressAdditional, street, zip, city, country, email } =
        this.profile || {};
      const streetParts = street?.split(' ');
      const streetNumber = streetParts.pop();
      const streetWithoutNumber = streetParts.join(' ');

      const htmlTable = `<table><tr>
        <td>${givenName} ${familyName}</td>
        <td>${addressAdditional || ''}</td>
        <td>${streetWithoutNumber}</td>
        <td>${streetNumber}</td>
        <td>${zip}</td>
        <td>${city}</td>
        <td>${country}</td>
        <td>House</td>
        <td></td>
        <td>${email}</td>
      </tr></table>`;

      navigator.clipboard.writeText(htmlTable);
      this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
        message: 'Adresse in die Zwischenablage kopiert',
      });
    },
  },
};
</script>
