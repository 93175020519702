var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitRecall},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',{staticClass:"flex space-x-4"},[(!_vm.isAddTrialPeriod)?_c('validation-provider',{attrs:{"name":"Ende Probestudium","rules":_vm.isGraduateTrialPeriod
            ? {
                required: true,
                date_format: true,
                after: { date: _vm.studyStart, inclusive: true },
                before: { date: _vm.todayDate, inclusive: true },
              }
            : {},"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('e-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isAddTrialPeriod),expression:"!isAddTrialPeriod"}],attrs:{"id":"trial-period-end-date","type":"date","label":"Ende Probestudium","disabled":!_vm.isGraduateTrialPeriod,"wide":"","errors":errors,"required":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})]}}],null,false,3326720228)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Dauer Probestudium","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('e-multiselect',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAddTrialPeriod),expression:"isAddTrialPeriod"}],attrs:{"id":"booking-trialPeriod-duration","searchable":"","options":_vm.availableTrialPeriodDurations,"option-label":"name","track-by":"duration","label":"Dauer Probestudium","errors":errors,"required":"","wide":"","mapped":""},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})]}}])}),_c('validation-provider',{attrs:{"name":"Erreichte ECTS Anzahl","rules":"required|integer|min_value:0|max_value:15","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('e-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGraduateTrialPeriod),expression:"isGraduateTrialPeriod"}],attrs:{"id":"trial-period-ects","label":"Erreichte ECTS Anzahl","errors":errors,"required":"","disabled":"","wide":""},model:{value:(_vm.ects),callback:function ($$v) {_vm.ects=_vm._n($$v)},expression:"ects"}})]}}])})],1)]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v(" Ausführen ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }