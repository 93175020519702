<template>
  <Loader v-if="loading" />
  <process-modal-form
    v-else
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitChangeRevocationRequst"
  >
    <template #form-content>
      <div class="grid grid-cols-6 gap-x-6 mt-6 gap-y-3 grid-flow-row">
        <div class="col-span-6">
          <validation-provider
            v-slot="{ errors }"
            name="Neue Widerrufsfrist"
            :rules="{
              date_format: true,
              after: { date: revocationDateMin, inclusive: true },
              before: { date: revocationDateMax, inclusive: true },
            }"
            slim
            immediate
          >
            <e-text-field
              id="recall-interruption-revocationDate"
              v-model="revocationDate"
              type="date"
              label="Neue Widerrufsfrist"
              wide
              :errors="errors"
              required
            />
          </validation-provider>
        </div>
        <!-- Description -->
        <div class="col-span-6">
          <validation-provider
            v-slot="{ errors }"
            name="Grund angeben"
            rules="required"
            immediate
          >
            <e-multiselect
              id="change-revocation-period-reason"
              v-model="reason"
              searchable
              :options="availableReasons"
              option-label="name"
              track-by="key"
              label="Grund angeben"
              :errors="errors"
              required
              mapped
            />
          </validation-provider>
        </div>

        <div v-if="isOtherReason" class="col-span-6">
          <validation-provider
            v-slot="{ errors }"
            name="Neuen Grund angeben"
            rules="required|max:100"
            immediate
          >
            <e-text-field
              id="change-revocation-period-otherReason"
              v-model="reasonOther"
              label="Neuen Grund angeben"
              :errors="errors"
            />
          </validation-provider>
        </div>
      </div>
    </template>

    <template #submit-button-label>Speichern</template>
  </process-modal-form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import Loader from '@/components/shared/Loader';

import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import {
  API_ENDPOINTS,
  CHANGE_REVOCATION_PERIOD_REASON,
  DATE_FORMAT,
} from '@/common/constants';
import { EMultiselect, ETextField } from '@careerpartner/nitro';
import { Getters as BookingGetters } from '@/store/modules/booking/types';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';

export default {
  name: 'CurriculumChangeModal',
  components: {
    EMultiselect,
    ETextField,
    ProcessModalForm,
    ValidationProvider,
    Loader,
  },
  mixins: [],

  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: '',
      waitingForResponse: false,
      revocationDateMin: null,
      revocationDateMax: null,
      reason: '',
      reasonOther: '',
    };
  },

  computed: {
    isOtherReason() {
      return (
        CHANGE_REVOCATION_PERIOD_REASON[this.reason] ===
        CHANGE_REVOCATION_PERIOD_REASON.OTHER
      );
    },
    availableReasons() {
      return Object.entries(CHANGE_REVOCATION_PERIOD_REASON).map(
        ([key, name]) => ({
          key,
          name,
        }),
      );
    },
  },

  async beforeMount() {
    this.setDates();
    this.reason = '';
    this.reasonOther = '';
  },

  methods: {
    setDates() {
      this.revocationDate = dayjs(
        this.$store.getters[
          `booking/${BookingGetters.GET_CURRENT_REVOCATION_DEADLINE}`
        ],
      ).format(DATE_FORMAT);

      const plusOneMonthDate = dayjs(
        this.$store.getters[
          `booking/${BookingGetters.GET_CURRENT_BOOKING_START_DATE}`
        ],
      ).add(1, 'months');

      this.revocationDateMin = (
        plusOneMonthDate.isAfter(dayjs()) ? plusOneMonthDate : dayjs()
      ).format(DATE_FORMAT);

      this.revocationDateMax = dayjs(
        this.$store.getters[
          `booking/${BookingGetters.GET_CURRENT_BOOKING_START_DATE}`
        ],
      )
        .add(3, 'months')
        .format(DATE_FORMAT);
    },
    async submitChangeRevocationRequst() {
      this.waitingForResponse = true;
      const bookingId =
        this.$store.getters[`booking/${BookingGetters.GET_CURRENT_BOOKING_ID}`];

      const requestUrl = API_ENDPOINTS.CHANGE_REVOCATION_DEADLINE.replace(
        ':bookingId',
        bookingId,
      );

      let finalDescription = CHANGE_REVOCATION_PERIOD_REASON[this.reason];

      if (this.isOtherReason) {
        finalDescription = this.reasonOther;
      }

      const payload = {
        newRevocationDeadlineDate: this.revocationDate,
        reason: finalDescription,
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Widerrufsfrist wurde erfolgreich angepasst.',
        });

        this.$emit('changed-revocation-date-successfull');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
