var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitRecall},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('validation-provider',{attrs:{"name":_vm.dateNaming,"rules":{
        required: true,
        date_format: true,
        after: { date: _vm.recallDateMin, inclusive: true },
        before: { date: _vm.recallDateMax, inclusive: true },
      },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"booking-cancellation-recall-date","type":"date","label":_vm.dateNaming,"wide":"","errors":errors,"required":""},model:{value:(_vm.recallDate),callback:function ($$v) {_vm.recallDate=$$v},expression:"recallDate"}})]}}])})]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v(" Speichern ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }