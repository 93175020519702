export const STUDENT_ENROLMENT_BASE = '/student-enrolment/v1';
export const DOCUMENT_BUILDER_BASE = '/reportbuilder/v1';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const PAGE_TITLE_TEMPLATE = '| Buchungsverwaltung | EPOS | IU';

export const API_ENDPOINTS = {
  FEATURE_FLAGS: `/student-enrolment/app/feature-flags`,
  CUSTOMER_PROFILE: `${STUDENT_ENROLMENT_BASE}/profiles/:profileId`,
  CUSTOMER_BOOKINGS: `${STUDENT_ENROLMENT_BASE}/profiles/:profileId/booking`,
  PROFILE_OWNERSHIP: `${STUDENT_ENROLMENT_BASE}/profiles/:profileId/ownership`,
  BOOKING_DETAIL: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId`,
  BOOKING_SEMESTER_HISTORY: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/semester-history`,
  BOOKING_ADMISSIONS: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/admission-programs`,
  MATRICULATION: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/matriculation`,
  CONDITIONS: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/conditions`,
  CANCELLATION: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/cancellation`,
  RECALL: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/cancellation-recall`,
  GRADUATION: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/graduation`,
  END_TRIAL_PERIOD: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/end-trial-period`,
  ADD_TRIAL_PERIOD: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/trial-period`,
  INTERRUPTION: `${STUDENT_ENROLMENT_BASE}/profiles/:profileId/interruption`,
  RECALL_INTERRUPTION: `${STUDENT_ENROLMENT_BASE}/profiles/:profileId/interruption/recall`,
  VALIDATE_ADD_INTERRUPTION: `${STUDENT_ENROLMENT_BASE}/profiles/:profileId/interruption/validate-add-interruption`,
  PROFILE_ACCOMODATION: `${STUDENT_ENROLMENT_BASE}/profiles/:profileId/accommodation`,
  BOOKING_AVAILABLE_CURRICULA: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/available-curricula`,
  AVAILABLE_ADMISSION_PROGRAMS: `${STUDENT_ENROLMENT_BASE}/program/admission-programs`,
  PROGRAM_AVAILABLE_CURRICULA: `${STUDENT_ENROLMENT_BASE}/program/:careProgramId/available-curricula`,
  POSTPONE_STUDY_START: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/postpone`,
  ADD_ADMISSION: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/admission-programs`,
  PATCH_ADMISSION: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/admission-programs/:admissionId`,
  DELETE_ADMISSION: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/admission-programs/:admissionId`,
  REQUEST_CURRICULUM_CHANGE: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/curriculum-change-request`,
  CANCEL_CURRICULUM_CHANGE: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/curriculum-change-request/:changeId/cancellation`,
  CHANGE_REVOCATION_DEADLINE: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/change-revocation-deadline`,
  GET_ACADEMIC_DISTANCE_LEARNING_PROGRAMS: `${STUDENT_ENROLMENT_BASE}/program/academic-distance-learning-programs`,
  GET_AVAILABLE_PROGRAM_CHANGE_PROGRAMS: `${STUDENT_ENROLMENT_BASE}/program/available-program-change-programs`,
  GET_PROGRAM_CHANGE_REQUESTS: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/program-change-request`,
  CREATE_PROGRAM_CHANGE_REQUEST: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/program-change-request`,
  REVOKE_PROGRAM_CHANGE_REQUEST: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/program-change-request`,
  RECOGNIZE_SEMESTERS: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/recognize-semesters`,
  GET_TIME_MODEL_CHANGE_REQUESTS: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/time-model-change-request`,
  REQUEST_TIME_MODEL_CHANGE: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/time-model-change-request`,
  REVOKE_TIME_MODEL_CHANGE: `${STUDENT_ENROLMENT_BASE}/booking/:bookingId/time-model-change-request`,
};

export const DOCBUILDER_API_ENDPOINTS = {
  QUERIES: `${DOCUMENT_BUILDER_BASE}/bindings/queries`,
  VARIABLES: `${DOCUMENT_BUILDER_BASE}/bindings/variables`,
  TAGS: `${DOCUMENT_BUILDER_BASE}/tags`,
  TEMPLATE: `${DOCUMENT_BUILDER_BASE}/document-templates/:id`,
  TEMPLATE_BINDING_PARAMS: `${DOCUMENT_BUILDER_BASE}/document-templates/:id/binding-params`,
  TEMPLATE_VERSION_LIST: `${DOCUMENT_BUILDER_BASE}/versions/list`,
  VERSION_BINDING_PARAMS: `${DOCUMENT_BUILDER_BASE}/versions/:id/binding-params`,
  GENERATE_PDF: `${DOCUMENT_BUILDER_BASE}/document-templates/:templateId/versions/:versionId/pdf/upload`,
};

export const ACADEMIC_CATEGORY_TYPE = {
  academic: 'Akademisches Produkt',
  nonAcademic: 'Nichtakademisches Produkt',
};

export const LISTENER_STATUS = {
  primary: 'Haupthörer',
  secondary: 'Nebenhörer',
  guest: 'Zweiter Nebenhörer',
};

export const TIME_MODELS = {
  fullTime: 'Vollzeit',
  partTime: 'Teilzeit I',
  partTimeTwo: 'Teilzeit II',
  unknown: 'Unbekannt',
};

export const TIME_MODEL_OPTIONS = [
  { id: 'fullTime', name: 'Vollzeit' },
  { id: 'partTime', name: 'Teilzeit 1' },
  {
    id: 'partTimeTwo',
    name: 'Teilzeit 2',
  },
];

export const ENROLMENT_STATUS_STRINGS = {
  ENDED: 'ended',
  ENROLED: 'enroled',
  ENROLED_WITH_CONDITIONS: 'enroledWithConditions',
  GRADUATED: 'graduated',
  IN_ADMISSION: 'inAdmission',
  INTERRUPTED: 'interrupted',
  SIGNED: 'signed',
  STUDYING: 'studying',
  STUDYING_WITH_CONDITIONS: 'studyingWithConditions',
};

export const ENROLMENT_STATUS = {
  ended: 'Beendet',
  enroled: 'Immatrikuliert',
  enroledWithConditions: 'Immatrikuliert u.V.',
  graduated: 'Beendet',
  inAdmission: 'In Zulassung',
  interrupted: 'Beurlaubt',
  signed: 'Vertragsabschluss',
  studying: 'Im Studium',
  studyingWithConditions: 'Im Studium u.V.',
};

export const STATUS_CONDITIONS_STRINGS = {
  QUALIFICATION_NOT_NOTARISED: 'qualification_not_notarised',
};

export const STATUS_HEADLINE = {
  EXMATRICULATED: 'exmatrikuliert am',
};

export const ADMISSION_PROGRAM_STATUS = {
  DRAFT: 'draft',
  IN_PROGRESS: 'in_progress',
  RESULTS_PENDING: 'results_pending',
  PASSED: 'passed',
  FAILED: 'failed',
};

export const ADMISSION_PROGRAM_TYPE = {
  CURRICULAR: 'curricular',
  NON_CURRICULAR: 'non_curricular',
};

export const SEMESTER_TYPES = {
  firstEnrolment: 'Ersteinschreiber',
  newEnrolment: 'Neueinschreiber',
  returningStudent: 'Rückmelder',
  interrupted: 'Beurlaubt',
};

export const SEASON_SHORT = {
  summer: 'SS',
  winter: 'WS',
};

export const STUDY_STATES = {
  presence: 'Präsenzstudium',
  distance: 'Fernstudium',
  presenceAbroad: 'Ausland - Präsenzstudium',
};

export const ERROR_TRANSLATION_TOKENS = {
  validation_error: 'Validierung fehlgeschlagen',
  general_error: 'Ein Fehler ist aufgetreten.',
  serverNotAvailable: 'Der Server ist aktuell nicht erreichbar.',
  cancellationMissingCategory: 'Es muss ein Beendigungsgrund ausgewählt sein.',
  cancellationMissingDescription:
    'Es muss eine Beschreibung für den Beendigungsgrund geben.',
  cancellationExmatriculated: 'Die Buchung ist bereits exmatrikuliert.',
  alreadyCancelled: 'Die Buchung wurde bereits beendet.',
  cancellationDateInFuture:
    'Datum Studienende darf nicht in der Zukunft liegen.',
  contractEndBeforeCancellationDate:
    'Datum Studienende muss nach Datum der Kündigung liegen.',
  contractEndBeforeStudyEnd:
    'Datum Studienende muss vor Studienende der Buchung liegen.',
  contractEndBeforeStudyStart:
    'Datum Studienende muss nach Studienstart der Buchung liegen.',
  cancellationDateBeforeStudyStart:
    'Datum der Kündigung muss nach Studienstart der Buchung liegen.',
  cancellationDateBeforeRevocationDeadline:
    'Datum der Kündigung und Datum Studienende müssen nach der Widerrufsfrist liegen.',
  contractEndTooEarly:
    'Datum Studienende muss innerhalb der ersten 7 Monate nach Studienstart der Buchung liegen.',
  contractEndAfterRevocationDeadline:
    'Datum Studienende muss innerhalb der Widerrufsfrist der Buchung liegen.',
  contractEndDifferentDay:
    'Datum Studienende muss am gleichen Tag des Monats wie der Studienstart der Buchung liegen.',
  contractEndDuringFirstSixMonths:
    'Datum Studienende muss mindestens 6 Monate nach Studienstart der Buchung liegen.',
  contractEndNotThreeMonthsToCancellationDate:
    'Datum der Kündigung muss mindestens 3 Monate vor Datum Studienende liegen.',
  contractEndFailedTrialInvalid:
    'Datum der Kündigung muss zwischen 12-24 Monaten nach Studienstart der Buchung liegen.',
  recallNoCancellation: 'Der Buchung liegt keine Kündigung vor.',
  contractCancelledMoreThanThreeMonthOfStudyStart:
    'Ein Widerruf kann nicht später als 3 Monate nach Studienbeginn erfolgen.',
  recallReasonInvalid:
    'Die Kündigung der Buchung kann nicht widerrufen werden.',
  enrolStudyTermsHigherUniversityTerms:
    'Anzahl absolvierter Fachsemester kann nicht größer als bereits hinterlegte Semester im Profil sein.',
  bookingMissingData: 'Erforderliche Daten der Buchung sind nicht vorhanden.',
  exmatriculationDateBeforeStudyStart:
    'Datum Studienende kann nicht vorm Studienstart sein.',
  exmatriculationDateAfterStudyEnd:
    'Datum Studienende kann nicht nach Studienende sein.',
  exmatriculationDateAfterSemesterStudyEnd:
    'Datum Studienende kann nicht nach Semesterende sein.',
  exmatriculationDateBeforeGraduationExamDate:
    'Datum Studienende kann nicht vor letzter Klausur sein.',
  curriculumNotFoundForStartDate:
    'Verschieben des Studienstarts nicht möglich, da noch keine Curriculumsversion für den gewählten Zeitraum angelegt wurde.',
  studiesBeganAlready:
    'Aktualisierung des Studienbeginns nicht möglich, da das Studium bereits begonnen hat.',
  interruptionOverlapping:
    'Es kann keine neue Beurlaubung erstellt werden, da es bereits eine laufende im gewählten Zeitraum gibt.',
  interruptionExpired:
    'Eine Beurlaubung kann nicht in der Vergangenheit starten.',
  interruptionTmcDuringInterruption:
    'In diesem Zeitraum findet ein Zeitmodellwechsel statt. Eine Beurlaubung kann nicht parallel hinterlegt werden',
  trialPeriodInvalidEndDate: 'Ungültiges Ende Probestudium.',
  trialPeriodNull: 'Die Buchung hat kein Probestudium.',
  revocationDeadlineChangeInvalidDate:
    'Das Widerrufsdatum muss innerhalb folgendem Zeitfenster liegen: Startdatum +1 Monat - +3 Monate, ebenfalls darf das Datum neue Datum nicht in der Vergangenheit liegen.',
  revocationDeadlineChangeDeadlineInPast:
    'Das bisherige Widerrufsdatum ist bereits abgelaufen, eine Änderung ist nicht zulässig.',
  pcrNoCareCohortId:
    'Es konnte kein Studiengangwechsel durchgeführt werden, da die Kohorte nicht gefunden wurde.',
  pcrStudyStartNotNextSemesterStart:
    'Es konnte kein Studiengangwechsel durchgeführt werden, da das Startdatum kein Semesterstart ist.', // pcr - already pending pcr for given booking
  pcrHasAlreadyPendingRequest:
    'Es konnte kein Studiengangwechsel durchgeführt werden, da es bereits eine offene Anfrage gibt.',
  pcrAlreadyInCourse:
    'Es kann kein Studiengangwechsel in den selben Kurs beantragt werden.',
  recognitionBookingNotEnroled:
    'Es kann keine Anerkennung beantragt werden, da die Buchung nicht eingeschrieben ist.',
  recognitionTooManySemesters: 'Es können maximal 6 Semester anerkannt werden.',
  timeModelChangeIsUnknown:
    'Es kann kein Zeitmodellwechsel durchgeführt werden, da das Zeitmodell unbekannt ist.',
  timeModelChangeBookingIsNotEnrolled:
    'Es kann kein Zeitmodellwechsel durchgeführt werden, da die Buchung nicht immatrikuliert ist.',
  timeModelChangeBookingIsInterrupted:
    'Es kann kein Zeitmodellwechsel durchgeführt werden, da die Buchung beurlaubt ist.',
  timeModelChangeStartDateIsInvalid:
    'Es kann kein Zeitmodellwechsel durchgeführt werden, da das Startdatum ungültig ist.',
  timeModelChangeBookingNotFound:
    'Es kann kein Zeitmodellwechsel durchgeführt werden, da die Buchung nicht gefunden wurde.',
  timeModelChangeProgramIsNotAcademic:
    'Es kann kein Zeitmodellwechsel durchgeführt werden, da das Programm nicht akademisch ist.',
  timeModelChangeUsesOldTimeModel:
    'Es kann kein Zeitmodellwechsel durchgeführt werden, da das Zeitmodell bereits verwendet wird.',
  timeModelChangeNoRevocableFound:
    'Es konnte kein Zeitmodellwechsel widerrufen werden, da kein offener Antrag gefunden wurde.',

  // ADMISSION
  admissionInDraftAlreadyExists:
    'Das Zulassungsprogramm ist bereits als Entwurf vorhanden.',
  admissionOnMatriculatedBooking:
    'Das Zulassungsprogramm kann nicht auf einer immatrikulierter Buchung hinzugefügt werden.',
  admissionAlreadyConfirmed: 'Das Zulassungsprogramm wurde bereits bestätigt.',
  admissionAlreadyExists: 'Das Zuassungsprogramm ist bereits vorhanden.',
  admissionAlreadyFailed:
    'Buchung hat bereits durchgefallende Zulassungsprogramme.',
};

export const FEATURES = {
  BOOKING_COMPLETION: 'EP-5939', // "Buchung beenden": Widerruf, Kündigung, Graduierung
  BOOKING_COMPLETION_CANCELLATION: 'EP-14282-cancellation',
  BOOKING_COMPLETION_GRADUATION: 'EP-14282-graduation',
  TRIAL_PERIOD: 'EP-5940', // "Probestudium"
  CHANGE_STUDY_DATES: 'EP-5941', // "Vertragsänderungen"
  INTERRUPTION: 'EP-4547', // Beurlaubung
  DOCUMENT_DOWNLOAD: 'EP-5426',
  ADMISSION_PROGRAM: 'EP-5263', // Zulassungsprogramme (außercurricular) hinzufügen
  CLICK_AND_STUDY: 'EP-7019', // Click And Study
  CHANGE_CURRICULUM: 'EP-5392', // Change Curriculum
  CHANGE_PROGRAM: 'EP-5393', // Change Program
  CHANGE_REVOCATION_DEADLINE: 'EP-4007', // Änderung der Widerrufsfrist
  SEPERATE_PROFILE_OWNERSHIP: 'EP-9569', // allow distinction between bookings being handled on care or epos
  TIME_MODEL: 'EP-7060', // Timemodel gets shown in booking overview
  CHANGE_TIME_MODEL: 'EP-5572', // Change Time Model
  ADMISSION_STATUS_CHANGE: 'EP-13544', // Admission Status Change
};

export const ERROR_CONSTANTS = {
  SERVER_NOT_AVAILABLE: 'server_not_available',
  GENERAL_ERROR: 'general_error',
  VALIDATION_ERROR: 'validation_error',
};

export const CURRICULUM_CHANGE_STATUS = {
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  APPLIED: 'applied',
  REVOKED: 'revoked',
};

export const OWNING_SYSTEM = {
  EPOS: 'epos',
  CARE: 'care',
};

export const PROGRAM_CHANGE_REQUEST_STATUS = {
  PENDING: 'pending',
  CANCELLED: 'cancelled',
  APPLIED: 'applied',
  REVOKED: 'revoked',
};

export const TIME_MODEL_CHANGE_REQUEST_STATUS = {
  PENDING: 'pending',
  CANCELED: 'canceled',
  APPLIED: 'applied',
  REVOKED: 'revoked',
};

export const RESIGNATION_REASON = {
  CARE_MODIFIED: 'careModified',
  BOOKING_PROGRAM_NOT_ACTIVE: 'bookingProgramNotActive',
  EPOS_FEATURE_MISSING: 'eposFeatureMissing',
};

export const RESIGNATION_DESCRIPTION = {
  RECOGNITION: 'Anerkennung',
  EDIT_ADMISSION: 'Zulassung bearbeiten, löschen, nicht-/ bestehen',
  ADJUST_LISTENER_STATUS: 'Hörerstatus anpassen (Haupthörer / Nebenhörer)',
  CHANGE_OF_COURSE: 'Studiengangwechsel',
  CURRICULUM_CHANGE: 'Curriculumwechsel',
  CHANGE_TIME_MODEL: 'Zeitmodellwechsel',
  STUDY_MODEL_CHANGE: 'Studienmodellwechsel',
  CREATE_EDIT_LEAVE_SEMESTER: 'Urlaubssemester anlegen, bearbeiten',
  ADDITIONAL_DISADVANTAGE_COMPENSATION:
    'Nachteilsausgleich zusätzliche Kompensationen',
  CONTRACT_CONCLUSION: 'Auf Vertragsabschluss setzen',
  CUSTOMIZE_ENROLEE_TYPE: 'Erst-/Neueinschreiber-Typ anpassen',
  REVOCATION_TERMINATION: 'Widerruf + Kündigung',
  EXMA_MISSING_DOC: 'Exma fehlende HZB / fehlende Unterlagen',
  EXMA_OPEN_FEE: 'Exma offene Gebühren',
  EXMA_ENB: 'Exma enB',
  GRADUATION: 'Graduierung',
  FINANCIAL_DATA_CLEANSE: 'Finanz-Datenbereinigung',
  POSTPONE_TAX_RETROACTIVE: 'StSt rückwirkend verschieben',
  ORDER_STUDENT_ID_CARD: 'Studierendenausweis bestellen',
  OTHER: 'Sonstiges',
};

export const CHANGE_REVOCATION_PERIOD_REASON = {
  ACCESS_DELAY: 'Verspätung Zugangsdaten',
  INCORRECT_COHORT: 'Falsche Kohorte',
  INCORRECT_BOOKING_DATA: 'Falsche Daten in der Buchung',
  ENROLLMENT_ERROR: 'Fehlerhafte Einschreibung',
  COURSE_BOOKING_ERROR: 'Fehlerhafte Kursbuchung',
  MYCAMPUS_ERROR: 'myCampus Fehler',
  COURTESY_CASE: 'Kulanzfall',
  OTHER: 'Sonstiges',
};
