import './config/set-public-path';
import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import router from './router';
import store from './store';
import App from './App.vue';
import sentryInit, { Sentry } from './plugins/sentry';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import '@careerpartner/nitro/dist/styles.css';
import './assets/tailwind.css';
import VueMeta from 'vue-meta';
import FeaturePlugin from '@/plugins/feature';
import { loadEnabledFeatures } from './utils/feature-config';

Vue.config.productionTip = false;

Vue.config.errorHandler = (error) => {
  Sentry.captureException(error);
  throw error;
};

const CONTAINER_SELECTOR = '#app-placeholder';

// Plugins
Vue.use(VueMeta);
Vue.use(FeaturePlugin);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    router,
    store,
    render(h) {
      return h(App);
    },
    el: CONTAINER_SELECTOR,
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const unmount = vueLifecycles.unmount;

export async function mount(props) {
  sentryInit();
  await loadEnabledFeatures();
  await vueLifecycles.mount(props);
}

export const devtools = {
  overlays: {
    selectors: [CONTAINER_SELECTOR],
  },
};
