<template>
  <div>
    <accordion v-model="accordionOpen" class="p-2">
      <template slot="accordion-trigger">
        <div class="flex justify-between">
          <h3 class="flex justify-center items-center">
            <e-icon style="font-size: 2rem" class="text-cta" :icon="icon" />
            <span class="text-black font-bold">Nachteilsausgleich</span>
          </h3>
          <e-chip class="uppercase text-white bg-success">
            <div class="flex justify-center items-center">
              <e-icon icon="mdi-check" />
              <span>Aktiv</span>
            </div>
          </e-chip>
        </div>
      </template>
      <template slot="accordion-content">
        <div class="ml-8 mr-8 mb-4">
          <p class="mb-4">
            {{ informationText }}
          </p>

          <div class="flex justify-between font-bold uppercase text-cta">
            <e-button
              variant="blank"
              class="hover:text-cta-darker"
              @click="openModal"
              >Bearbeiten</e-button
            >
            <e-button
              variant="blank"
              class="hover:text-cta-darker"
              @click="deactiveProfileAccomodation"
              >Deaktivieren</e-button
            >
          </div>
        </div>
      </template>
    </accordion>
  </div>
</template>

<script>
import {
  Actions as ProfileActions,
  Getters as ProfileGetters,
} from '@/store/modules/profile/types';

import Accordion from '@/components/Accordion.vue';
import { EButton, EIcon, EChip } from '@careerpartner/nitro';
import { PROFILE_ACCOMODATION_EVENTS } from '@/common/events/profileAccomodationEvents';
import formatHelper from '@/mixins/formatHelper';

export default {
  name: 'ProfileAccomodation',
  components: {
    EChip,
    EButton,
    EIcon,
    Accordion,
  },
  mixins: [formatHelper],
  data() {
    return {
      accordionOpen: false,
    };
  },
  computed: {
    icon() {
      return this.accordionOpen ? 'mdi-menu-down' : 'mdi-menu-right';
    },
    profileAccomodationData() {
      return this.$store.getters[
        `profile/${ProfileGetters.PROFILE_ACCOMODATION}`
      ];
    },
    informationText() {
      if (!this.profileAccomodationData) {
        return;
      }

      const payload = this.profileAccomodationData;

      const examTimeExtensionMessage = `eine Prüfungszeitverlängerung um ${payload.examTimeExtension}%`;
      const studyTimeExtensionMessage = `eine erweiterte kostenlose Verlängerung der Studienlaufzeit um ${payload.studyTimeExtension}%`;

      let finalMessage = `Es wird `;

      if (payload.examTimeExtension > 0 && payload.studyTimeExtension > 0) {
        finalMessage += ` ${studyTimeExtensionMessage} und ${examTimeExtensionMessage}`;
      } else if (payload.examTimeExtension > 0) {
        finalMessage += examTimeExtensionMessage;
      } else if (payload.studyTimeExtension > 0) {
        finalMessage += studyTimeExtensionMessage;
      }

      let validationDate = `für immer`;
      if (payload.validUntil) {
        validationDate = `bis zum ${this.formatDate(payload.validUntil)}`;
      }

      finalMessage += ` für alle Buchungen ${validationDate} gestattet.`;

      return finalMessage;
    },
  },
  methods: {
    async deactiveProfileAccomodation() {
      await this.$store.dispatch(
        `profile/${ProfileActions.DELETE_PROFILE_ACCOMODATION}`,
        this.$route.params.id,
      );
    },
    openModal() {
      this.$emit(
        'open-modal',
        PROFILE_ACCOMODATION_EVENTS.ON_SET_PROFILE_ACCOMODATION,
      );
    },
  },
};
</script>
