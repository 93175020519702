import dayjs from 'dayjs';
import { CANCEL_REASON_KEYS } from '@/common/selectLists/cancellationReasons';
import { ADMISSION_PROGRAM_STATUS, DATE_FORMAT } from '@/common/constants';
import Store from '@/store';
import { Getters as BookingGetters } from '@/store/modules/booking/types';

export default function ({
  cancelReason,
  revocationDeadline,
  studyStartDate,
  studyEndDate,
  today = dayjs(),
  cancellationDate = dayjs(),
  earliestTrialPeriodEndDate,
  latestTrialPeriodEndDate,
}) {
  const cancelDate = {
    rangeStart: '',
    rangeEnd: '',
    suggestion: '',
  };

  const newStudyEndDate = {
    rangeStart: '',
    rangeEnd: '',
    suggestion: '',
  };

  const todayFormatted = today.format(DATE_FORMAT);
  const afterRevocationDeadline = revocationDeadline
    .add(1, 'day')
    .format(DATE_FORMAT);
  const startOfStudy = studyStartDate.format(DATE_FORMAT);
  const endOfStudy = studyEndDate.format(DATE_FORMAT);
  const twoWeeksAfterCancellation = cancellationDate
    .add(2, 'weeks')
    .format(DATE_FORMAT);
  const threeMonthsAfterCancellation = cancellationDate
    .add(3, 'month')
    .format(DATE_FORMAT);
  const sixMonthsAfterStudyStart = studyStartDate
    .add(6, 'month')
    .format(DATE_FORMAT);

  if (
    cancelReason === CANCEL_REASON_KEYS.REGULAR ||
    cancelReason === CANCEL_REASON_KEYS.EXTRAORDINARY
  ) {
    cancelDate.suggestion = todayFormatted;
    cancelDate.rangeStart = afterRevocationDeadline;
    cancelDate.rangeEnd = endOfStudy;

    if (cancelReason === CANCEL_REASON_KEYS.REGULAR) {
      newStudyEndDate.suggestion = dayjs(threeMonthsAfterCancellation).isAfter(
        endOfStudy,
      )
        ? endOfStudy
        : threeMonthsAfterCancellation;
      newStudyEndDate.rangeStart = sixMonthsAfterStudyStart;
      newStudyEndDate.rangeEnd = endOfStudy;
    }

    if (cancelReason === CANCEL_REASON_KEYS.EXTRAORDINARY) {
      newStudyEndDate.suggestion = dayjs(twoWeeksAfterCancellation).isAfter(
        endOfStudy,
      )
        ? endOfStudy
        : twoWeeksAfterCancellation;
      newStudyEndDate.rangeStart = afterRevocationDeadline;
      newStudyEndDate.rangeEnd = endOfStudy;
    }
  }

  if (cancelReason === CANCEL_REASON_KEYS.GRADUATION) {
    cancelDate.suggestion = todayFormatted;
    cancelDate.rangeStart = startOfStudy;
    cancelDate.rangeEnd = endOfStudy;

    newStudyEndDate.suggestion = todayFormatted;
    newStudyEndDate.rangeStart = startOfStudy;
    newStudyEndDate.rangeEnd = endOfStudy;
  }

  if (
    [
      CANCEL_REASON_KEYS.HZB,
      CANCEL_REASON_KEYS.DOCUMENTS_NOT_SUBMITTED,
    ].includes(cancelReason)
  ) {
    const sevenMonthsAfterStudyStart = studyStartDate
      .add(7, 'month')
      .format(DATE_FORMAT);

    cancelDate.suggestion = today.isAfter(dayjs(sevenMonthsAfterStudyStart))
      ? sevenMonthsAfterStudyStart
      : todayFormatted;
    cancelDate.rangeStart = startOfStudy;
    cancelDate.rangeEnd = sevenMonthsAfterStudyStart;

    newStudyEndDate.suggestion = today.isAfter(
      dayjs(sevenMonthsAfterStudyStart),
    )
      ? sevenMonthsAfterStudyStart
      : todayFormatted;
    newStudyEndDate.rangeStart = startOfStudy;
    newStudyEndDate.rangeEnd = sevenMonthsAfterStudyStart;
  }

  if (
    [
      CANCEL_REASON_KEYS.FEES,
      CANCEL_REASON_KEYS.HEALTH_INSURANCE,
      CANCEL_REASON_KEYS.FAILED_EXAM,
    ].includes(cancelReason)
  ) {
    cancelDate.suggestion = todayFormatted;
    cancelDate.rangeStart = startOfStudy;
    cancelDate.rangeEnd = endOfStudy;

    newStudyEndDate.suggestion = cancellationDate.format(DATE_FORMAT);
    newStudyEndDate.rangeStart = cancellationDate.format(DATE_FORMAT);
    newStudyEndDate.rangeEnd = cancellationDate.format(DATE_FORMAT);
  }

  if (cancelReason === CANCEL_REASON_KEYS.FAILED_TRIAL) {
    const minAfterStudyStart = earliestTrialPeriodEndDate.format(DATE_FORMAT);
    const maxAfterStudyStart = latestTrialPeriodEndDate.format(DATE_FORMAT);

    let suggestion = todayFormatted;
    if (today.isBefore(dayjs(minAfterStudyStart))) {
      suggestion = minAfterStudyStart;
    } else if (today.isAfter(maxAfterStudyStart)) {
      suggestion = maxAfterStudyStart;
    }

    const rangeEnd = today.isAfter(maxAfterStudyStart)
      ? todayFormatted
      : maxAfterStudyStart;

    cancelDate.suggestion = suggestion;
    cancelDate.rangeStart = minAfterStudyStart;
    cancelDate.rangeEnd = rangeEnd;

    newStudyEndDate.suggestion = suggestion;
    newStudyEndDate.rangeStart = minAfterStudyStart;
    newStudyEndDate.rangeEnd = rangeEnd;
  }

  if (cancelReason === CANCEL_REASON_KEYS.ADMISSION_PROGRAM) {
    const admissionPrograms =
      Store.getters[`booking/${BookingGetters.GET_ADMISSION_COURSES}`];

    const admissionProgramEndDate = admissionPrograms.find(
      (ap) => ap.status === ADMISSION_PROGRAM_STATUS.FAILED,
    )?.updatedAt;

    cancelDate.suggestion = todayFormatted;
    cancelDate.rangeStart = admissionProgramEndDate;
    cancelDate.rangeEnd = endOfStudy;

    newStudyEndDate.suggestion = todayFormatted;
    newStudyEndDate.rangeStart = admissionProgramEndDate;
    newStudyEndDate.rangeEnd = endOfStudy;
  }

  return { cancelDate, newStudyEndDate };
}
