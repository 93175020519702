var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"py-6 text-center"},[_c('p',[_vm._v("Daten werden vorbereitet...")])]):_c('div',[_c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalidForm,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitForm},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',{staticClass:"grid grid-cols-12 gap-x-6 mt-6"},[_c('div',{staticClass:"col-span-6"},[_c('e-text-field',{attrs:{"id":"originalStudyStart","value":_vm.originalStudyStart,"type":"date","label":"Derzeitiger Studienstart","disabled":""}})],1),_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Neuer Studienstart","rules":{
              required: true,
              date_format: true,
              after: { date: _vm.today, inclusive: true },
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"studyStart","type":"date","required":"","errors":errors,"label":"Neuer Studienstart"},model:{value:(_vm.updatedStudyStart),callback:function ($$v) {_vm.updatedStudyStart=$$v},expression:"updatedStudyStart"}})]}}])})],1)]),_c('div',{staticClass:"grid grid-cols-12 gap-x-6 mt-6"},[_c('div',{staticClass:"col-span-6"},[_c('e-text-field',{attrs:{"id":"endRegularStudies","value":_vm.endRegularStudies,"type":"date","label":"Ende Regelstudienzeit","disabled":""}})],1)]),(_vm.differentCurriculumRequired)?_c('div',[_c('p',{staticClass:"font-bold mb-2"},[_vm._v(" Die Umbuchung in eine andere Curriculumversionen ist notwendig: ")]),_c('validation-provider',{attrs:{"name":"Curriculumsversion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"new-curriculum","searchable":"","options":_vm.availableCurricula,"option-label":"name","track-by":"id","label":"Curriculumsversion","errors":errors,"disabled":!!_vm.error,"required":"","mapped":""},model:{value:(_vm.selectedCareCohortId),callback:function ($$v) {_vm.selectedCareCohortId=$$v},expression:"selectedCareCohortId"}})]}}],null,false,4104681452)})],1):_vm._e()]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Speichern")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }