<template>
  <div>
    <e-chip
      class="font-bold"
      :class="
        academicProgram ? 'text-white bg-blue-600' : 'text-black bg-blue-200'
      "
      >{{ academicCateogry }}</e-chip
    >
    <h4 v-if="item.program" class="text-2xl font-bold uppercase w-4/5">
      {{ programName }}
    </h4>
    <dl class="flex mt-2 space-x-8">
      <definition-item
        :column="false"
        headline="Studienstart:"
        :content="formatDate(item.startDate)"
      />
      <div class="inline-flex">
        <dt class="font-bold">Ende Regelstudienzeit:</dt>
        <dd class="text-gray-700 ml-1">
          <div class="inline-flex">
            {{ endRegularStudies }}
          </div>
        </dd>
      </div>
      <definition-item
        v-if="isTimeModelEnabled"
        :column="false"
        headline="Zeitmodell:"
        :content="timeModel"
      />
    </dl>
    <dl class="flex space-x-16 mt-9">
      <definition-item
        v-for="(info, i) in studyInfo(item)"
        :key="i"
        :headline="info.headline"
        :content="info.content"
        :content-tooltip="info.contentTooltip"
      />
    </dl>
  </div>
</template>

<script>
import { EChip } from '@careerpartner/nitro';

import DefinitionItem from '@/components/shared/DefinitionItem';
import {
  ACADEMIC_CATEGORY_TYPE,
  FEATURES,
  LISTENER_STATUS,
  TIME_MODELS,
} from '@/common/constants';
import formatHelper from '@/mixins/formatHelper';
import { campuses } from '@/common/selectLists/campuses';
import dayjs from 'dayjs';
import { isActive } from '@/utils/feature-config';

export default {
  name: 'OverviewInformation',
  components: {
    EChip,
    DefinitionItem,
  },
  mixins: [formatHelper],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    academicProgram() {
      return this.item.category === 'academic';
    },
    academicCateogry() {
      return ACADEMIC_CATEGORY_TYPE[this.item.category];
    },
    timeModel() {
      return TIME_MODELS[this.item.timeModel] || TIME_MODELS.unknown;
    },
    isTimeModelEnabled() {
      return isActive(FEATURES.TIME_MODEL);
    },
    endRegularStudies() {
      if (this.item.cancellationDetails) {
        return this.formatDate(this.item.cancellationDetails.contractEnd);
      }

      if (this.item.graduationDetails) {
        return this.formatDate(this.item.graduationDetails.exmatriculationDate);
      }

      return this.formatDate(dayjs(this.item.endDate));
    },
    programName() {
      return this.item.program.shortName
        ? this.item.program.shortName + ' - ' + this.item.program.title
        : this.item.program.title;
    },
  },
  methods: {
    studyInfo(item) {
      const getCampus = (id) => {
        if (!id) return false;
        const campus = campuses.find((campus) => campus.externalId === id);
        return `${campus.name} (${campus.abbreviation})`;
      };

      return [
        {
          headline: 'Hörerstatus',
          content: LISTENER_STATUS[item.listenerStatus] || '-',
          contentTooltip:
            'Hörerstatus kann über Anpassungen im Statistik-Reiter (Parallele Einschreibung) geändert werden.',
        },
        {
          headline: 'Campus',
          content: getCampus(item.campus) || '-',
        },
        {
          headline: 'Curriculum',
          content: item.program?.curriculum?.name || '-',
        },
      ];
    },
  },
};
</script>
