<template>
  <e-modal
    size="lg"
    :is-open="value"
    display-close-button
    allow-overflow
    :auto-height="autoHeight"
    :title="title"
    @close="closeModal"
  >
    <validation-observer
      :ref="`${id}-form`"
      v-slot="{ invalid }"
      :vid="`${id}-form`"
      slim
    >
      <slot :invalid="invalid" />
    </validation-observer>
  </e-modal>
</template>

<script>
import { EModal } from '@careerpartner/nitro';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'ProcessModalWrapper',
  components: {
    EModal,
    ValidationObserver,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    autoHeight: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    closeModal() {
      if (confirm('Prozess abbrechen?')) {
        this.$emit('input', false);
      }
    },
  },
};
</script>
