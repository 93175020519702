<template>
  <Loader v-if="loading" />
  <process-modal-form
    v-else
    class="w-full"
    :invalid="invalid"
    :error="error"
    :waiting-for-response="waitingForResponse"
    @submit="submitTimeModelChangeRequest"
  >
    <template #form-content>
      <validation-provider
        v-slot="{ errors }"
        name="Zeitmodell"
        rules="required"
        slim
      >
        <e-multiselect
          id="time-model"
          v-model="timeModel"
          :errors="errors"
          :options="availableTimeModels"
          option-label="name"
          track-by="id"
          label="Zeitmodell"
          searchable
          required
          mapped
        />
      </validation-provider>
      <validation-provider v-slot="{ errors }">
        <e-text-field
          id="time-model-change-apply-date"
          v-model="startDate"
          type="date"
          label="Wechseldatum"
          wide
          :errors="errors"
          required
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        rules="required|integer|min_value:0"
      >
        <e-text-field
          id="time-model-change-course-fee"
          v-model="courseFee"
          type="number"
          label="Neuer Rechnungsbetrag"
          wide
          :errors="errors"
          required
        />
      </validation-provider>
    </template>

    <template #submit-button-label>Speichern</template>
  </process-modal-form>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { EMultiselect, ETextField } from '@careerpartner/nitro';
import Loader from '@/components/shared/Loader';

import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import { API_ENDPOINTS, TIME_MODEL_OPTIONS } from '@/common/constants';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { Actions as AppActions } from '@/store/modules/app/types';
import sortHelper from '@/mixins/sortHelper';
import dateHelper from '@/mixins/dateHelper';

export default {
  name: 'TimeModelChangeModal',
  components: {
    ProcessModalForm,
    ValidationProvider,
    EMultiselect,
    ETextField,
    Loader,
  },
  mixins: [sortHelper, dateHelper],

  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: '',
      waitingForResponse: false,
      ready: false,
      startDate: '',
      timeModel: '',
      courseFee: '',
    };
  },

  computed: {
    availableTimeModels() {
      return TIME_MODEL_OPTIONS.filter(
        (timeModel) => timeModel.id !== this.booking.timeModel,
      );
    },
  },

  methods: {
    async submitTimeModelChangeRequest() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.REQUEST_TIME_MODEL_CHANGE.replace(
        ':bookingId',
        this.$route.params.bookingId,
      );

      const payload = {
        startDate: this.startDate,
        timeModel: this.timeModel,
        courseFee: parseInt(this.courseFee),
      };

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Zeitmodell erfolgreich angelegt.',
        });

        this.$emit('time-model-change-successful');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
