import { EDIT_BOOKING_EVENTS } from '@/common/events/editBookingEvents';
import {
  CURRICULUM_CHANGE_STATUS,
  ENROLMENT_STATUS_STRINGS,
  FEATURES,
} from '@/common/constants';
import { isActive } from '@/utils/feature-config';

const editBookingMenuItems = [
  {
    id: EDIT_BOOKING_EVENTS.ON_CHANGE_CURRICULUM,
    text: 'Curriculumwechsel',
    event: 'menuButtonClicked',
    disabled: true,
    showBottomSeparator: false,
  },
  {
    id: EDIT_BOOKING_EVENTS.ON_CANCEL_CURRICULUM_CHANGE,
    text: 'Curriculumwechsel widerrufen',
    event: 'menuButtonClicked',
    disabled: true,
    showBottomSeparator: true,
  },
  {
    id: EDIT_BOOKING_EVENTS.ON_CHANGE_PROGRAM,
    text: 'Studiengangwechsel',
    event: 'menuButtonClicked',
    disabled: true,
    showBottomSeparator: false,
  },
  {
    id: EDIT_BOOKING_EVENTS.ON_CHANGE_PROGRAM_REVOKED,
    text: 'Studiengangwechsel widerrufen',
    event: 'menuButtonClicked',
    disabled: true,
    showBottomSeparator: true,
  },
  {
    id: EDIT_BOOKING_EVENTS.ON_CHANGE_TIME_MODEL,
    text: 'Zeitmodellwechsel',
    event: 'menuButtonClicked',
    disabled: true,
    showBottomSeparator: false,
  },
  {
    id: EDIT_BOOKING_EVENTS.ON_REVOKE_TIME_MODEL_CHANGE,
    text: 'Zeitmodellwechsel widerrufen',
    event: 'menuButtonClicked',
    disabled: true,
    showBottomSeparator: false,
  },
];

export default function (booking, hasPcr, bookingHasRevocableTmcrs) {
  for (const item of editBookingMenuItems) {
    item.disabled = true;
  }

  const enableItem = (key) => {
    const index = editBookingMenuItems.findIndex((x) => x.id === key);
    editBookingMenuItems[index].disabled = false;
  };

  if (isActive(FEATURES.CHANGE_PROGRAM)) {
    enableItem(EDIT_BOOKING_EVENTS.ON_CHANGE_PROGRAM);

    if (hasPcr) {
      enableItem(EDIT_BOOKING_EVENTS.ON_CHANGE_PROGRAM_REVOKED);
    }
  }

  const bookingIsActive = [
    ENROLMENT_STATUS_STRINGS.STUDYING,
    ENROLMENT_STATUS_STRINGS.STUDYING_WITH_CONDITIONS,
    ENROLMENT_STATUS_STRINGS.ENROLED,
    ENROLMENT_STATUS_STRINGS.ENROLED_WITH_CONDITIONS,
  ].includes(booking?.status?.name);

  const hasPendingCcrs = !!booking.curriculumChangeRequests?.find(
    (ccr) => ccr.status === CURRICULUM_CHANGE_STATUS.PENDING,
  );

  if (
    isActive(FEATURES.CHANGE_CURRICULUM) &&
    bookingIsActive &&
    !hasPendingCcrs
  ) {
    enableItem(EDIT_BOOKING_EVENTS.ON_CHANGE_CURRICULUM);
  }

  const hasCancelableCcrs = !!booking.curriculumChangeRequests?.find((ccr) =>
    [
      CURRICULUM_CHANGE_STATUS.PENDING,
      CURRICULUM_CHANGE_STATUS.APPLIED,
    ].includes(ccr.status),
  );

  if (
    isActive(FEATURES.CHANGE_CURRICULUM) &&
    bookingIsActive &&
    hasCancelableCcrs
  ) {
    enableItem(EDIT_BOOKING_EVENTS.ON_CANCEL_CURRICULUM_CHANGE);
  }

  if (isActive(FEATURES.CHANGE_TIME_MODEL) && bookingIsActive) {
    enableItem(EDIT_BOOKING_EVENTS.ON_CHANGE_TIME_MODEL);
  }

  if (isActive(FEATURES.CHANGE_TIME_MODEL) && bookingHasRevocableTmcrs) {
    enableItem(EDIT_BOOKING_EVENTS.ON_REVOKE_TIME_MODEL_CHANGE);
  }
  // add logic to show which button is enabled, depending on status
  return editBookingMenuItems;
}
