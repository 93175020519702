import { ENROLMENT_STATUS_STRINGS } from '@/common/constants';
import { TRIAL_PERIOD_EVENTS } from '@/common/events/trialStudyEvents';
import Store from '@/store';
import { Getters as BookingGetters } from '@/store/modules/booking/types';

const trialPeriodMenuItems = [
  {
    id: TRIAL_PERIOD_EVENTS.ON_ADD_TRIAL,
    text: 'Probestudium hinzufügen',
    disabled: true,
    event: 'menuButtonClicked',
  },
  {
    id: TRIAL_PERIOD_EVENTS.ON_GRADUATE_TRIAL,
    text: 'Bestehen von Probestudium',
    disabled: true,
    event: 'menuButtonClicked',
  },
  {
    id: TRIAL_PERIOD_EVENTS.ON_END_TRIAL_WITH_DOCUMENTS,
    text: 'Beenden, da Unterlagen nachgereicht',
    disabled: true,
    event: 'menuButtonClicked',
  },
  {
    id: TRIAL_PERIOD_EVENTS.ON_REVOKE_TRIAL,
    text: 'Widerruf aufgrund von Korrektur',
    disabled: true,
    event: 'menuButtonClicked',
  },
];

export default function (testBooking) {
  const currentBooking =
    testBooking ||
    Store.getters[`booking/${BookingGetters.GET_CURRENT_BOOKING}`];

  if (
    currentBooking?.trialPeriod !== null &&
    currentBooking?.trialPeriod.passed === null
  ) {
    trialPeriodMenuItems.forEach((item) => (item.disabled = false));
  } else {
    trialPeriodMenuItems.forEach((item) => (item.disabled = true));
  }

  const bookingEnded = [
    ENROLMENT_STATUS_STRINGS.ENDED,
    ENROLMENT_STATUS_STRINGS.GRADUATED,
  ].includes(currentBooking.status?.name);

  // adding of trial is inverse to the cancel/revoke steps, needs to be handled by itself
  if (!bookingEnded) {
    trialPeriodMenuItems.forEach((item) => {
      if (item.id === TRIAL_PERIOD_EVENTS.ON_ADD_TRIAL) {
        item.disabled = !!currentBooking.trialPeriod;
      }
    });
  }

  return trialPeriodMenuItems;
}
