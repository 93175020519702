import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from './ApiServiceWrapper';
import store from '@/store';
import { Actions as AppActions } from '@/store/modules/app/types';

let enabledFeatures = [];

export const isActive = (feature) => enabledFeatures.includes(feature);
export const isInactive = (feature) => !isActive(feature);

export const loadEnabledFeatures = async () => {
  const featureFlagsUrl = API_ENDPOINTS.FEATURE_FLAGS;
  let loadedFeatures = [];

  try {
    const { data: result } = await ApiServiceWrapper.get(featureFlagsUrl);
    loadedFeatures = result;
  } catch (error) {
    store.dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
  }

  enabledFeatures = loadedFeatures
    .filter((ff) => ff.isActive)
    .map((ff) => ff.featureFlag);
};
