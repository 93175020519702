import { Actions, Mutations } from './types';
import { Actions as AppActions, Mutations as AppMutations } from '../app/types';

import { API_ENDPOINTS } from '@/common/constants';
import { ApiServiceWrapper } from '@/utils/ApiServiceWrapper';
import dayjs from 'dayjs';

export default {
  async [Actions.LOAD_BOOKINGS]({ commit, dispatch }, profileId) {
    commit(`app/${AppMutations.INCREMENT_LOADING}`, {}, { root: true });

    const customerBookingsUrl = API_ENDPOINTS.CUSTOMER_BOOKINGS.replace(
      ':profileId',
      profileId,
    );

    try {
      const { data } = await ApiServiceWrapper.get(customerBookingsUrl);
      commit(Mutations.SET_BOOKINGS, data);
    } catch (error) {
      dispatch(`app/${AppActions.SET_ERROR}`, error, { root: true });
    } finally {
      commit(`app/${AppMutations.DECREMENT_LOADING}`, {}, { root: true });
    }
  },
  async [Actions.LOAD_SINGLE_BOOKING]({ commit, dispatch }, bookingId) {
    commit(`app/${AppMutations.INCREMENT_LOADING}`, {}, { root: true });

    const detailBookingUrl = API_ENDPOINTS.BOOKING_DETAIL.replace(
      ':bookingId',
      bookingId,
    );
    const bookingHistoryUrl = API_ENDPOINTS.BOOKING_SEMESTER_HISTORY.replace(
      ':bookingId',
      bookingId,
    );
    const bookingAdmissionCourses = API_ENDPOINTS.BOOKING_ADMISSIONS.replace(
      ':bookingId',
      bookingId,
    );

    const matriculationUrl = API_ENDPOINTS.MATRICULATION.replace(
      ':bookingId',
      bookingId,
    );

    const conditionsUrl = API_ENDPOINTS.CONDITIONS.replace(
      ':bookingId',
      bookingId,
    );

    try {
      const { data: booking } = await ApiServiceWrapper.get(detailBookingUrl);
      const { data: history } = await ApiServiceWrapper.get(bookingHistoryUrl);
      history.sort((a, b) => dayjs(a.startDate) < dayjs(b.startDate));

      const { data: matriculation } = await ApiServiceWrapper.get(
        matriculationUrl,
      );

      // gracefully return empty array incase this booking has no admissionCourses
      const { data: admissionCourses } = await ApiServiceWrapper.get(
        bookingAdmissionCourses,
      ).catch(() => ({
        data: {
          addmissionCourses: [],
        },
      }));

      const { data: conditions } = await ApiServiceWrapper.get(
        conditionsUrl,
      ).catch(() => []);

      const detailData = {
        ...booking,
        history,
        matriculation,
        admissionCourses,
        conditions,
      };

      commit(Mutations.SET_CURRENT_BOOKING, detailData);
    } catch (e) {
      dispatch(`app/${AppActions.SET_ERROR}`, e, { root: true });
    } finally {
      commit(`app/${AppMutations.DECREMENT_LOADING}`, {}, { root: true });
    }
  },
  async [Actions.LOAD_SINGLE_BOOKING_WITH_DELAY](
    { commit, dispatch },
    bookingId,
  ) {
    commit(`app/${AppMutations.INCREMENT_LOADING}`, {}, { root: true });

    // frontend is too fast and might fetch booking before backend updated the needed state
    const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await timeout(500);

    dispatch(Actions.LOAD_SINGLE_BOOKING, bookingId);

    commit(`app/${AppMutations.DECREMENT_LOADING}`, {}, { root: true });
  },
};
