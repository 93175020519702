var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',[_c('Loader')],1):_c('div',[_c('care-ownership-info-banner'),_c('process-modal-wrapper',{attrs:{"id":"graduation-process","title":"Buchung graduieren"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('graduation-modal',{attrs:{"id":"graduation-process","invalid":invalid,"booking":_vm.currentBooking},on:{"graduation-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showGraduationModal),callback:function ($$v) {_vm.showGraduationModal=$$v},expression:"showGraduationModal"}}),_c('process-modal-wrapper',{attrs:{"id":"add-admission","title":_vm.admissionModalTitle},on:{"input":function($event){_vm.currentAdmission = null}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('admissions-modal',{attrs:{"id":"upsert-admission-process-modal","admission-id":_vm.admissionId,"invalid":invalid,"booking":_vm.currentBooking},on:{"update-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showAdmissionModal),callback:function ($$v) {_vm.showAdmissionModal=$$v},expression:"showAdmissionModal"}}),_c('delete-admission-modal',{attrs:{"id":"delete-admission-process-modal","admission-id":_vm.admissionId,"booking":_vm.currentBooking},on:{"deletion-successful":_vm.closeModalAndReload},model:{value:(_vm.showDeleteAdmissionModal),callback:function ($$v) {_vm.showDeleteAdmissionModal=$$v},expression:"showDeleteAdmissionModal"}}),_c('process-modal-wrapper',{attrs:{"id":"end-trial-period-process","title":_vm.endTrialPeriodModalTitle},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('end-trial-period-modal',{attrs:{"id":"end-trial-period-process","invalid":invalid,"booking":_vm.currentBooking,"action-name":_vm.actionName},on:{"end-trial-period-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showEndTrialPeriodModal),callback:function ($$v) {_vm.showEndTrialPeriodModal=$$v},expression:"showEndTrialPeriodModal"}}),_c('process-modal-wrapper',{attrs:{"id":"cancellation-process","title":"Buchung kündigen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('cancellation-modal',{attrs:{"id":"cancellation-process","booking":_vm.currentBooking,"invalid":invalid},on:{"cancellation-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showCancellationModal),callback:function ($$v) {_vm.showCancellationModal=$$v},expression:"showCancellationModal"}}),_c('process-modal-wrapper',{attrs:{"id":"revocation-process","title":"Buchung widerrufen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('revocation-modal',{attrs:{"id":"revocation-process","invalid":invalid,"booking":_vm.currentBooking},on:{"revocation-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showRevocationModal),callback:function ($$v) {_vm.showRevocationModal=$$v},expression:"showRevocationModal"}}),_c('process-modal-wrapper',{attrs:{"id":"recall-process","title":"Kündigung oder Widerruf zurückziehen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('recall-modal',{attrs:{"id":"recall-process","invalid":invalid,"booking":_vm.currentBooking},on:{"recall-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showRecallModal),callback:function ($$v) {_vm.showRecallModal=$$v},expression:"showRecallModal"}}),(_vm.programChangeEnabled)?_c('process-modal-wrapper',{attrs:{"id":"program-change-modal","auto-height":true,"title":"Studiengangwechsel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('program-change-modal',{attrs:{"id":"program-change","invalid":invalid,"booking":_vm.currentBooking},on:{"program-change-requested-successfully":_vm.closeModalAndReload}})]}}],null,false,671377884),model:{value:(_vm.showProgramChangeModal),callback:function ($$v) {_vm.showProgramChangeModal=$$v},expression:"showProgramChangeModal"}}):_vm._e(),(_vm.programChangeEnabled)?_c('process-modal-wrapper',{attrs:{"id":"revoke-program-change-modal","auto-height":true,"title":"Studiengangwechsel widerrufen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('revoke-program-change-modal',{attrs:{"id":"revoke-program-change","invalid":invalid,"booking":_vm.currentBooking},on:{"program-change-request-revoked-successfully":_vm.closeModalAndReload}})]}}],null,false,1132489204),model:{value:(_vm.showRevokeProgramChangeModal),callback:function ($$v) {_vm.showRevokeProgramChangeModal=$$v},expression:"showRevokeProgramChangeModal"}}):_vm._e(),(_vm.timeModelChangeEnabled)?_c('process-modal-wrapper',{attrs:{"id":"time-model-change-modal","auto-height":true,"title":"Zeitmodellwechsel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('time-model-change-modal',{attrs:{"id":"time-model-change","invalid":invalid,"booking":_vm.currentBooking},on:{"time-model-change-successful":_vm.closeModalAndReload}})]}}],null,false,3155392567),model:{value:(_vm.showTimeModelChangeModal),callback:function ($$v) {_vm.showTimeModelChangeModal=$$v},expression:"showTimeModelChangeModal"}}):_vm._e(),(_vm.timeModelChangeEnabled)?_c('process-modal-wrapper',{attrs:{"id":"revoke-time-model-change-modal","auto-height":true,"title":"Zeitmodellwechsel widerrufen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('revoke-time-model-change-modal',{attrs:{"id":"time-model-change","invalid":invalid,"booking":_vm.currentBooking},on:{"time-model-change-revoked-successfully":_vm.closeModalAndReload}})]}}],null,false,11180710),model:{value:(_vm.showRevokeTimeModelChangeModal),callback:function ($$v) {_vm.showRevokeTimeModelChangeModal=$$v},expression:"showRevokeTimeModelChangeModal"}}):_vm._e(),_c('process-modal-wrapper',{attrs:{"id":"moving-study-start-modal","title":"Studienstart verschieben"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('update-study-start-process-modal',{attrs:{"id":"moving-study-start","invalid":invalid,"booking":_vm.currentBooking},on:{"update-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showUpdateStudyStartModal),callback:function ($$v) {_vm.showUpdateStudyStartModal=$$v},expression:"showUpdateStudyStartModal"}}),_c('process-modal-wrapper',{attrs:{"id":"document-download-modal","auto-height":false,"title":"Dokumentendownload"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('document-download-modal',{attrs:{"id":"document-download","invalid":invalid,"booking":_vm.currentBooking},on:{"update-successful":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showDocumentDownloadModal),callback:function ($$v) {_vm.showDocumentDownloadModal=$$v},expression:"showDocumentDownloadModal"}}),(_vm.curriculumChangeEnabled)?_c('process-modal-wrapper',{attrs:{"id":"curriculum-change-modal","auto-height":true,"title":"Curriculumwechsel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('curriculum-change-modal',{attrs:{"id":"curriculum-change","invalid":invalid,"booking":_vm.currentBooking},on:{"curriculum-change-requested-successfully":_vm.closeModalAndReload}})]}}],null,false,1889427829),model:{value:(_vm.showCurriculumChangeModal),callback:function ($$v) {_vm.showCurriculumChangeModal=$$v},expression:"showCurriculumChangeModal"}}):_vm._e(),_c('process-modal-wrapper',{attrs:{"id":"change-revocation-date","title":"Widerrufsfrist ändern"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('change-revocation-date-modal',{attrs:{"id":"recall-process","invalid":invalid,"booking":_vm.currentBooking,"auto-height":true},on:{"changed-revocation-date-successfull":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showChangeRevocationDateModal),callback:function ($$v) {_vm.showChangeRevocationDateModal=$$v},expression:"showChangeRevocationDateModal"}}),_c('process-modal-wrapper',{attrs:{"id":"add-recognition-modal","title":"Fachsemester anerkennen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('add-recognition-modal',{attrs:{"id":"add-recognition","invalid":invalid,"booking":_vm.currentBooking,"auto-height":true},on:{"added-recognition-successfully":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showRecognitionModal),callback:function ($$v) {_vm.showRecognitionModal=$$v},expression:"showRecognitionModal"}}),(_vm.curriculumChangeEnabled)?_c('cancel-curriculum-change-modal',{attrs:{"id":"cancel-curriculum-change-modal","booking":_vm.currentBooking},on:{"curriculum-change-canceled-successfully":_vm.closeModalAndReload},model:{value:(_vm.showCancelCurriculumChangeModal),callback:function ($$v) {_vm.showCancelCurriculumChangeModal=$$v},expression:"showCancelCurriculumChangeModal"}}):_vm._e(),_c('process-modal-wrapper',{attrs:{"id":"cancellation-process","title":"In Care bearbeiten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ownership-resignate-modal',{attrs:{"id":"resign-ownership-modal","profile-id":_vm.$route.params.id,"invalid":invalid},on:{"ownership-resigned":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showOwnershipModal),callback:function ($$v) {_vm.showOwnershipModal=$$v},expression:"showOwnershipModal"}}),_c('booking-detail-toolbar',{on:{"toolbar-action":_vm.handleEvent}}),_c('error-banner'),_c('div',{staticClass:"mt-12 z-100"},[_c('general-booking-details',{attrs:{"booking":_vm.currentBooking}}),(_vm.currentBooking.admissionCourses.length)?_c('admission-courses',{attrs:{"admission-courses":_vm.currentBooking.admissionCourses},on:{"edit-admission":_vm.editAdmission,"delete-admission":_vm.deleteAdmission}}):_vm._e(),_c('semester-history',{attrs:{"booking":_vm.currentBooking},on:{"show-recognition-modal":_vm.handleEvent}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }