var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitInterruption},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',[_c('validation-provider',{attrs:{"name":"Grund des Widerrufs","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"interruption-reason","searchable":"","options":_vm.availableRecallReasons,"option-label":"label","track-by":"key","label":"Grund des Widerrufs","errors":errors,"required":"","mapped":""},model:{value:(_vm.recallReason),callback:function ($$v) {_vm.recallReason=$$v},expression:"recallReason"}})]}}])})],1)]),_c('div',{staticClass:"grid grid-cols-12 gap-x-6 mt-6"},[_c('div',{staticClass:"col-span-6"},[_c('validation-provider',{attrs:{"name":"Eingangsdatum Widerruf","rules":{
            required: _vm.recallReason === _vm.recallInterruptionKeys.REVOCATION,
            date_format: true,
            after: { date: _vm.recallDateMin, inclusive: true },
            before: { date: _vm.recallDateMax, inclusive: true },
          },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"recall-interruption-revocationDate","type":"date","label":"Eingangsdatum Widerruf","wide":"","errors":errors,"required":"","disabled":_vm.isReasonManualCorrection},model:{value:(_vm.recallDate),callback:function ($$v) {_vm.recallDate=$$v},expression:"recallDate"}})]}}])})],1)])]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Speichern")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }