var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('process-modal-form',{attrs:{"id":_vm.id,"invalid":_vm.invalid || !_vm.interruptionIsValid,"error":_vm.error,"waiting-for-response":_vm.waitingForResponse},on:{"submit":_vm.submitInterruption},scopedSlots:_vm._u([{key:"form-content",fn:function(){return [_c('div',{staticClass:"flex flex-col space-y-6"},[_c('div',[_c('validation-provider',{attrs:{"name":"Grund für die Beurlaubung","rules":"required","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"interruption-reason","searchable":"","options":_vm.availableInterruptionReasons,"option-label":"label","track-by":"key","label":"Grund für die Beurlaubung","errors":errors,"required":"","mapped":""},model:{value:(_vm.interruptionReason),callback:function ($$v) {_vm.interruptionReason=$$v},expression:"interruptionReason"}})]}}])})],1),_c('div',[_c('validation-provider',{attrs:{"name":"Zeitraum","rules":{ required: _vm.isInterruptionRequired },"immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('e-multiselect',{attrs:{"id":"interruption-length","searchable":"","disabled":_vm.isInterruptionRequired === false,"options":_vm.availableInterruptionLengths,"option-label":"label","track-by":"key","label":"Zeitraum","errors":errors,"required":"","mapped":""},model:{value:(_vm.interruptionLength),callback:function ($$v) {_vm.interruptionLength=$$v},expression:"interruptionLength"}})]}}])})],1),_c('div',{staticClass:"flex space-x-4"},[_c('validation-provider',{attrs:{"name":"Start Beurlaubung","rules":{
            required: _vm.isInterruptionLengthRequired,
            date_format: true,
            after: { date: _vm.interruptionStartDateMin, inclusive: true },
            before: { date: _vm.interruptionStartDateMax, inclusive: true },
          },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"interruption-interruptionStartDate","type":"date","label":"Start Beurlaubung","disabled":_vm.isInterruptionLengthRequired === false,"wide":"","errors":errors,"required":""},model:{value:(_vm.interruptionStartDate),callback:function ($$v) {_vm.interruptionStartDate=$$v},expression:"interruptionStartDate"}})]}}])}),_c('validation-provider',{attrs:{"name":"Ende Beurlaubung","rules":{ required: true, date_format: true },"slim":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('e-text-field',{attrs:{"id":"interruption-interruptionEndDate","type":"date","label":"Ende Beurlaubung","wide":"","errors":errors,"required":"","disabled":""},model:{value:(_vm.interruptionEndDate),callback:function ($$v) {_vm.interruptionEndDate=$$v},expression:"interruptionEndDate"}})]}}])})],1),(_vm.admissionWarning)?_c('p',{staticClass:"text-status-orange"},[_c('e-icon',{staticClass:"mr-1",attrs:{"icon":"mdi-alert-outline","size":"xs"}}),_vm._v(" "+_vm._s(_vm.admissionWarning)+" ")],1):_vm._e(),(_vm.tmcError)?_c('p',{staticClass:"text-status-red"},[_c('e-icon',{staticClass:"mr-1",attrs:{"icon":"mdi-alert-outline","size":"xs"}}),_vm._v(" "+_vm._s(_vm.tmcError)+" ")],1):_vm._e()])]},proxy:true},{key:"submit-button-label",fn:function(){return [_vm._v("Hinzufügen")]},proxy:true},{key:"additional-content",fn:function(){return [_c('p',{staticClass:"text-status-orange text-sm"},[_vm._v(" Beurlaubung darf nur zum "),_c('span',{staticClass:"font-bold"},[_vm._v("persönlichen Monatsstart")]),_vm._v(" beginnen."),_c('br'),_c('span',{staticClass:"font-bold"},[_vm._v("Alle Buchungen")]),_vm._v(" werden auf den Status “Beurlaubt” gesetzt. ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }