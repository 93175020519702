var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_c('Loader')],1):_c('div',[_c('process-modal-wrapper',{attrs:{"id":"interruption-process-modal","title":"Beurlaubung hinzufügen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('interruption-process-modal',{attrs:{"id":"interruption-process","invalid":invalid,"booking":_vm.bookings[0]},on:{"set-interruption":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showInterruptionModal),callback:function ($$v) {_vm.showInterruptionModal=$$v},expression:"showInterruptionModal"}}),_c('process-modal-wrapper',{attrs:{"id":"profile-accomodation-process-wrapper","title":"Nachteilsausgleich aktivieren"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('profile-accomodation-process-modal',{attrs:{"id":"profile-accomodation-process-modal","invalid":invalid},on:{"set-profile-accomodation":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showProfileAccomodationModal),callback:function ($$v) {_vm.showProfileAccomodationModal=$$v},expression:"showProfileAccomodationModal"}}),_c('process-modal-wrapper',{attrs:{"id":"recall-interruption-modal","title":"Beurlaubung zurückziehen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('recall-interruption-modal',{attrs:{"id":"recall-interruption","invalid":invalid},on:{"recall-interruption":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showRecallInterruptionModal),callback:function ($$v) {_vm.showRecallInterruptionModal=$$v},expression:"showRecallInterruptionModal"}}),_c('booking-overview-toolbar',{on:{"toolbar-action":_vm.handleEvent}}),_c('process-modal-wrapper',{attrs:{"id":"resign-ownership-modal","title":"In Care bearbeiten"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('ownership-resignation-modal',{attrs:{"id":"resign-ownership-modal","profile-id":_vm.profileId,"invalid":invalid},on:{"ownership-resigned":_vm.closeModalAndReload}})]}}]),model:{value:(_vm.showOwnershipModal),callback:function ($$v) {_vm.showOwnershipModal=$$v},expression:"showOwnershipModal"}}),_c('error-banner'),_c('care-ownership-info-banner'),_c('div',{staticClass:"mt-12 z-100"},[_c('h3',{staticClass:"font-bold text-blue-600 uppercase"},[_vm._v("Einstellungen")]),_c('div',{staticClass:"pb-12 grid grid-cols-12"},[(
            _vm.isProfileAccomodationPresent && _vm.isProfileOwnedByCARE === false
          )?_c('profile-accomodation',{staticClass:"col-span-3",on:{"open-modal":_vm.handleEvent}}):(
            _vm.isProfileAccomodationPresent && _vm.isProfileOwnedByCARE === true
          )?_c('div',{staticClass:"col-span-6"},[_c('h5',{staticClass:"text-blue-600"},[_vm._v(" Zusätzliche Einstellungen können aktuell nur in CARE eingesehen und bearbeitet werden. ")])]):_c('div',{staticClass:"col-span-6"},[_c('h5',{staticClass:"text-blue-600"},[_vm._v(" Keine zusätzlichen Einstellungen aktiviert. ")])])],1),_c('h3',{staticClass:"font-bold text-blue-600 uppercase"},[_vm._v(" Übersicht der Buchungen ")]),(_vm.bookings.length)?_c('div',{staticClass:"flex flex-col mt-8 space-y-8"},_vm._l((_vm.bookings),function(booking){return _c('e-card',{key:booking.id,attrs:{"outlined":"","rounded":"","sheet":""},scopedSlots:_vm._u([{key:"sheet",fn:function(){return [_c('div',{staticClass:"\n                flex flex-col\n                px-8\n                py-4\n                space-y-8\n                md:space-y-0 md:grid md:grid-cols-12\n              "},[_c('div',{staticClass:"flex flex-col items-start col-span-9"},[_c('overview-information',{attrs:{"item":booking}})],1),_c('div',{staticClass:"\n                  flex flex-col\n                  pl-10\n                  border-l border-gray-300\n                  col-span-3\n                "},[_c('Status',{attrs:{"status":booking.status,"trial-period":booking.trialPeriod,"start-date":booking.startDate,"cancellation":booking.cancellationDetails,"graduation-details":booking.graduationDetails}}),_c('e-button',{staticClass:"mt-auto",attrs:{"type":"routerLink","variant":"primary","to":{
                    name: 'bookingDetail',
                    params: { bookingId: booking.id },
                  }}},[_vm._v("Details einsehen "),_c('e-icon',{staticClass:"ml-4",attrs:{"icon":"arrow-right"}})],1)],1)])]},proxy:true}],null,true)})}),1):_c('div',{staticClass:"flex flex-col items-center mt-20"},[_c('img',{attrs:{"src":_vm.emptyTableImg,"alt":""}}),_c('p',{staticClass:"mt-4 font-bold"},[_vm._v(" Für diesen Kunden sind noch keine Buchungen angelegt. ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }