<template>
  <div v-if="loading" class="py-6 text-center">
    <p>Daten werden vorbereitet...</p>
  </div>
  <div v-else>
    <process-modal-form
      :id="id"
      :invalid="invalidForm"
      :error="error"
      :waiting-for-response="waitingForResponse"
      @submit="submitForm"
    >
      <template #form-content>
        <div class="grid grid-cols-12 gap-x-6 mt-6">
          <div class="col-span-6">
            <e-text-field
              id="originalStudyStart"
              :value="originalStudyStart"
              type="date"
              label="Derzeitiger Studienstart"
              disabled
            />
          </div>

          <div class="col-span-6">
            <validation-provider
              v-slot="{ errors }"
              name="Neuer Studienstart"
              :rules="{
                required: true,
                date_format: true,
                after: { date: today, inclusive: true },
              }"
            >
              <e-text-field
                id="studyStart"
                v-model="updatedStudyStart"
                type="date"
                required
                :errors="errors"
                label="Neuer Studienstart"
              />
            </validation-provider>
          </div>
        </div>

        <div class="grid grid-cols-12 gap-x-6 mt-6">
          <div class="col-span-6">
            <e-text-field
              id="endRegularStudies"
              :value="endRegularStudies"
              type="date"
              label="Ende Regelstudienzeit"
              disabled
            />
          </div>
        </div>

        <div v-if="differentCurriculumRequired">
          <p class="font-bold mb-2">
            Die Umbuchung in eine andere Curriculumversionen ist notwendig:
          </p>
          <validation-provider
            v-slot="{ errors }"
            name="Curriculumsversion"
            rules="required"
          >
            <e-multiselect
              id="new-curriculum"
              v-model="selectedCareCohortId"
              searchable
              :options="availableCurricula"
              option-label="name"
              track-by="id"
              label="Curriculumsversion"
              :errors="errors"
              :disabled="!!error"
              required
              mapped
            />
          </validation-provider>
        </div>
      </template>
      <template #submit-button-label>Speichern</template>
    </process-modal-form>
  </div>
</template>

<script>
import { ETextField, EMultiselect } from '@careerpartner/nitro';
import { ValidationProvider } from 'vee-validate';
import { API_ENDPOINTS, ENROLMENT_STATUS_STRINGS } from '@/common/constants';
import dayjs from 'dayjs';
import { Actions as AppActions } from '@/store/modules/app/types';
import ProcessModalForm from '@/components/ProcessModals/shared/ProcessModalForm';
import formatHelper from '@/mixins/formatHelper';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import sortHelper from '@/mixins/sortHelper';

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export default {
  name: 'UpdateStudyStartProcessModal',
  components: {
    ETextField,
    ValidationProvider,
    ProcessModalForm,
    EMultiselect,
  },
  mixins: [formatHelper, sortHelper],
  props: {
    id: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: true,
    },
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      loading: true,
      selectedCareCohortId: '',
      updatedStudyStart: null,
      waitingForResponse: false,
      differentCurriculumRequired: false,
      availableCurricula: [],
    };
  },
  computed: {
    invalidForm() {
      return this.invalid || this.waitingForResponse;
    },
    today() {
      return dayjs().format('YYYY-MM-DD');
    },
    originalStudyStart() {
      return this.booking.startDate;
    },
    endRegularStudies() {
      if (!this.updatedStudyStart) {
        return null;
      }

      const originalStartStudyDate = dayjs(this.booking.startDate);
      const originalEndStudyDate = dayjs(this.booking.endDate);

      const duration = dayjs(this.updatedStudyStart).diff(
        originalStartStudyDate,
        'days',
      );

      return originalEndStudyDate.add(duration, 'days');
    },
  },
  watch: {
    updatedStudyStart(value) {
      // curriculum version verification is only required when the booking has already been matriculated.
      const statusName = this.booking.status?.name;
      const isMatriculated = [
        ENROLMENT_STATUS_STRINGS.ENROLED_WITH_CONDITIONS,
        ENROLMENT_STATUS_STRINGS.ENROLED,
      ].includes(statusName);

      if (isMatriculated === false) {
        return;
      }

      const newStudyStartDate = dayjs(value);
      this.availableCurricula.sort(
        (a, b) => dayjs(a.studyStart) - dayjs(b.studyStart),
      );
      const createStartSemesterList = (curricula) => {
        const semesters = [];
        const countKnownCurricula = curricula.length;
        for (let i = 0; i < countKnownCurricula; i++) {
          const isLastStartSemester = i + 1 === countKnownCurricula;
          const current = curricula[i];
          const semesterStart = current.studyStart;
          const nextSemesterStart = isLastStartSemester
            ? current.studyEnd
            : curricula[i + 1].studyStart;

          const semester = {
            id: current.id,
            name: current.name,
            semesterStart,
            nextSemesterStart,
          };

          semesters.push(semester);
        }

        return semesters;
      };

      const startSemesters = createStartSemesterList(this.availableCurricula);

      for (const startSemester of startSemesters) {
        const semesterStartDay = dayjs(startSemester.semesterStart);
        const nextSemesterStartDay = dayjs(startSemester.nextSemesterStart);

        const isWithinCurriculumBoundaries =
          newStudyStartDate.isSameOrAfter(semesterStartDay) && // First day must belong to the time range
          newStudyStartDate.isBefore(nextSemesterStartDay); // First day of next semester, so not in the boundaries

        const isSameSemester =
          this.booking.program?.curriculum?.id === startSemester.id;

        this.error = '';
        this.selectedCareCohortId = startSemester.id;

        if (isWithinCurriculumBoundaries && isSameSemester === true) {
          this.differentCurriculumRequired = false;
          return;
        } else if (isWithinCurriculumBoundaries) {
          this.differentCurriculumRequired = true;
          return;
        }
      }

      this.differentCurriculumRequired = true;
      this.selectedCareCohortId = null;
      this.error = 'curriculumNotFoundForStartDate';
    },
  },
  async beforeMount() {
    await this.loadAvailableCurricula();
  },
  methods: {
    async loadAvailableCurricula() {
      this.loading = true;

      const requestUrl = API_ENDPOINTS.BOOKING_AVAILABLE_CURRICULA.replace(
        ':bookingId',
        this.booking.id,
      );

      try {
        const {
          data: { availableCurricula },
        } = await ApiServiceWrapper.get(requestUrl);

        this.availableCurricula = this.sortCurricula(availableCurricula);
      } catch (e) {
        this.error = getApiError(e);
      }
      this.loading = false;
    },
    async submitForm() {
      this.waitingForResponse = true;

      const payload = {
        studyStart: this.updatedStudyStart,
        careCohortId: this.selectedCareCohortId,
      };

      const requestUrl = API_ENDPOINTS.POSTPONE_STUDY_START.replace(
        ':bookingId',
        this.$route.params.bookingId,
      );

      try {
        await ApiServiceWrapper.post(requestUrl, payload);

        let message = 'Studienstart wurde erfolgreich verschoben.';

        if (payload.careCohortId) {
          message =
            'Studienstart und Curriculumsversion wurden erfolgreich geändert.';
        }

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message,
        });

        this.$emit('update-successful');
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
  },
};
</script>
