<template>
  <e-modal
    size="sm"
    :is-open="value"
    allow-overflow
    :auto-height="true"
    @close="closeModal"
  >
    <div
      class="
        p-5
        grid grid-cols-6
        gap-y-3
        place-items-center
        grid-flow-row
        text-center
      "
    >
      <div class="col-span-12">
        <img :src="trashCanImage" alt="" class="h-20" />
      </div>

      <div class="col-span-12">
        <h2 class="font-bold">
          Bist Du sicher, dass Du das Zulassungsprogramm löschen möchtest
        </h2>
      </div>
      <div class="col-span-12 pb-12">
        <span class="col-span-12 text-gray-700">
          Student verliert unmittelbar den Zugriff auf alle Fächer des
          Zulassungsprogramms.
        </span>
      </div>
      <div class="col-span-6">
        <e-button variant="secondary" @click="closeModal">
          <span class="text-black">Abbrechen</span>
        </e-button>
      </div>
      <div class="col-span-6 justify-end">
        <e-button variant="primary" @click="submit">
          <span>Löschen</span>
        </e-button>
      </div>
    </div>
  </e-modal>
</template>

<script>
import { EModal, EButton } from '@careerpartner/nitro';
import { API_ENDPOINTS } from '@/common/constants';
import { Actions as AppActions } from '@/store/modules/app/types';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import { imagePaths } from '@/common/content';

export default {
  name: 'DeleteAdmissionModal',
  components: {
    EModal,
    EButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    booking: {
      type: Object,
      required: true,
    },
    admissionId: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: '',
      waitingForResponse: false,
    };
  },
  computed: {
    trashCanImage() {
      return imagePaths.TRASH_CAN_IMAGE_OF_DELETE_ADMISSION_MODAL;
    },
  },
  methods: {
    async submit() {
      this.waitingForResponse = true;

      const requestUrl = API_ENDPOINTS.DELETE_ADMISSION.replace(
        ':bookingId',
        this.booking.id,
      ).replace(':admissionId', this.admissionId);

      try {
        await ApiServiceWrapper.delete(requestUrl);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Zulassungsprogramm wurde erfolgreich gelöscht.',
        });

        this.$emit('deletion-successful');
        this.$emit('input', false);
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
      }
    },
    closeModal() {
      this.$emit('input', false);
    },
  },
};
</script>
