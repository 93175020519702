<template>
  <div>
    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <process-modal-wrapper
        id="interruption-process-modal"
        v-slot="{ invalid }"
        v-model="showInterruptionModal"
        title="Beurlaubung hinzufügen"
      >
        <interruption-process-modal
          id="interruption-process"
          :invalid="invalid"
          :booking="bookings[0]"
          @set-interruption="closeModalAndReload"
        />
      </process-modal-wrapper>

      <process-modal-wrapper
        id="profile-accomodation-process-wrapper"
        v-slot="{ invalid }"
        v-model="showProfileAccomodationModal"
        title="Nachteilsausgleich aktivieren"
      >
        <profile-accomodation-process-modal
          id="profile-accomodation-process-modal"
          :invalid="invalid"
          @set-profile-accomodation="closeModalAndReload"
        />
      </process-modal-wrapper>

      <process-modal-wrapper
        id="recall-interruption-modal"
        v-slot="{ invalid }"
        v-model="showRecallInterruptionModal"
        title="Beurlaubung zurückziehen"
      >
        <recall-interruption-modal
          id="recall-interruption"
          :invalid="invalid"
          @recall-interruption="closeModalAndReload"
        />
      </process-modal-wrapper>

      <booking-overview-toolbar @toolbar-action="handleEvent" />

      <process-modal-wrapper
        id="resign-ownership-modal"
        v-slot="{ invalid }"
        v-model="showOwnershipModal"
        title="In Care bearbeiten"
      >
        <ownership-resignation-modal
          id="resign-ownership-modal"
          :profile-id="profileId"
          :invalid="invalid"
          @ownership-resigned="closeModalAndReload"
        />
      </process-modal-wrapper>

      <!--      <ownership-resignation-modal-->
      <!--        id="transfer-ownership-to-care-modal"-->
      <!--        v-model="showOwnershipModal"-->
      <!--        :profile-id="$route.params.id"-->
      <!--        @ownership-resigned="closeModalAndReload"-->
      <!--      />-->

      <error-banner />

      <care-ownership-info-banner />
      <div class="mt-12 z-100">
        <h3 class="font-bold text-blue-600 uppercase">Einstellungen</h3>

        <div class="pb-12 grid grid-cols-12">
          <profile-accomodation
            v-if="
              isProfileAccomodationPresent && isProfileOwnedByCARE === false
            "
            class="col-span-3"
            @open-modal="handleEvent"
          />
          <div
            v-else-if="
              isProfileAccomodationPresent && isProfileOwnedByCARE === true
            "
            class="col-span-6"
          >
            <h5 class="text-blue-600">
              Zusätzliche Einstellungen können aktuell nur in CARE eingesehen
              und bearbeitet werden.
            </h5>
          </div>
          <div v-else class="col-span-6">
            <h5 class="text-blue-600">
              Keine zusätzlichen Einstellungen aktiviert.
            </h5>
          </div>
        </div>

        <h3 class="font-bold text-blue-600 uppercase">
          Übersicht der Buchungen
        </h3>
        <div v-if="bookings.length" class="flex flex-col mt-8 space-y-8">
          <e-card
            v-for="booking in bookings"
            :key="booking.id"
            outlined
            rounded
            sheet
          >
            <template #sheet>
              <div
                class="
                  flex flex-col
                  px-8
                  py-4
                  space-y-8
                  md:space-y-0 md:grid md:grid-cols-12
                "
              >
                <div class="flex flex-col items-start col-span-9">
                  <overview-information :item="booking" />
                  <!--                <a
                    href="http://www.some-out-link.de"
                    class="mt-8 font-bold uppercase text-cta hover:text-cta-darker"
                    >Vertragsunterlagen einsehen</a
                  >-->
                </div>
                <div
                  class="
                    flex flex-col
                    pl-10
                    border-l border-gray-300
                    col-span-3
                  "
                >
                  <Status
                    :status="booking.status"
                    :trial-period="booking.trialPeriod"
                    :start-date="booking.startDate"
                    :cancellation="booking.cancellationDetails"
                    :graduation-details="booking.graduationDetails"
                  />
                  <e-button
                    type="routerLink"
                    variant="primary"
                    class="mt-auto"
                    :to="{
                      name: 'bookingDetail',
                      params: { bookingId: booking.id },
                    }"
                    >Details einsehen
                    <e-icon icon="arrow-right" class="ml-4"></e-icon>
                  </e-button>
                </div>
              </div>
            </template>
          </e-card>
        </div>
        <div v-else class="flex flex-col items-center mt-20">
          <img :src="emptyTableImg" alt="" />
          <p class="mt-4 font-bold">
            Für diesen Kunden sind noch keine Buchungen angelegt.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Getters as ProfileGetters } from '@/store/modules/profile/types';
import {
  Actions as BookingActions,
  Getters as BookingGetters,
} from '@/store/modules/booking/types';
import { Getters as AppGetters } from '@/store/modules/app/types';
import { EButton, ECard, EIcon } from '@careerpartner/nitro';
import Status from '@/components/shared/Status/Status';
import OverviewInformation from '@/components/shared/OverviewInformation';
import formatHelper from '@/mixins/formatHelper';
import Loader from '@/components/shared/Loader';
import ErrorBanner from '@/components/shared/ErrorBanner';
import BookingOverviewToolbar from '@/components/BookingOverviewToolbar/Index';
import { INTERRUPTION_EVENTS } from '@/common/events/interruptionEvents';
import { PROFILE_ACCOMODATION_EVENTS } from '@/common/events/profileAccomodationEvents';
import { OWNERSHIP_EVENTS } from '@/common/events/ownershipEvents';
import ProcessModalWrapper from '@/components/ProcessModals/shared/ProcessModalWrapper';
import InterruptionProcessModal from '@/components/ProcessModals/InterruptionProcessModal';
import RecallInterruptionModal from '@/components/ProcessModals/RecallInterruptionModal';
import ProfileAccomodationProcessModal from '@/components/ProcessModals/ProfileAccomodationProcessModal';
import ProfileAccomodation from '@/components/ProfileAccomodation';
import CareOwnershipInfoBanner from '@/components/CareOwnershipInfoBanner';
import OwnershipResignationModal from '@/components/ProcessModals/OwnershipResignationModal';

export default {
  name: 'Bookings',
  components: {
    OwnershipResignationModal,
    RecallInterruptionModal,
    InterruptionProcessModal,
    ProfileAccomodationProcessModal,
    ProfileAccomodation,
    ProcessModalWrapper,
    BookingOverviewToolbar,
    ErrorBanner,
    Loader,
    OverviewInformation,
    Status,
    CareOwnershipInfoBanner,
    EButton,
    ECard,
    EIcon,
  },
  mixins: [formatHelper],
  data() {
    return {
      emptyTableImg:
        'https://res.cloudinary.com/iubh/image/upload/v1643799766/IT%20-%20Epos%20/illustrations/epos-booking-no-data-semestereinsicht.svg',
      showInterruptionModal: false,
      showRecallInterruptionModal: false,
      showProfileAccomodationModal: false,
      showOwnershipModal: false,
    };
  },
  computed: {
    bookings() {
      return this.$store.getters[`booking/${BookingGetters.GET_BOOKINGS}`];
    },
    profileId() {
      return this.$route.params.id;
    },
    isProfileAccomodationPresent() {
      return this.$store.getters[
        `profile/${ProfileGetters.PROFILE_ACCOMODATION}`
      ];
    },
    loading() {
      return this.$store.getters[`app/${AppGetters.LOADING}`];
    },
    isProfileOwnedByCARE() {
      return this.$store.getters[
        `profile/${ProfileGetters.IS_PROFILE_OWNED_BY_CARE}`
      ];
    },
  },

  async beforeMount() {
    await this.$store.dispatch(
      `booking/${BookingActions.LOAD_BOOKINGS}`,
      this.$route.params.id,
    );
  },

  methods: {
    [INTERRUPTION_EVENTS.ON_SET_INTERRUPTION]() {
      this.showInterruptionModal = true;
    },
    [INTERRUPTION_EVENTS.ON_RECALL_INTERRUPTION]() {
      this.showRecallInterruptionModal = true;
    },
    async closeModalAndReload() {
      this.showInterruptionModal = false;
      this.showRecallInterruptionModal = false;
      this.showProfileAccomodationModal = false;
      this.showOwnershipModal = false;

      // backend will take a bit for the update, if we fetch too quickly we get no changes
      setTimeout(() => {
        this.$store.dispatch(
          `booking/${BookingActions.LOAD_BOOKINGS}`,
          this.$route.params.id,
        );
      }, 500);
    },
    [PROFILE_ACCOMODATION_EVENTS.ON_SET_PROFILE_ACCOMODATION]() {
      this.showProfileAccomodationModal = true;
    },
    [OWNERSHIP_EVENTS.ON_RESIGNATE_OWNERSHIP]() {
      this.showOwnershipModal = true;
    },
    handleEvent(actionName) {
      return this[actionName]();
    },
  },
};
</script>
