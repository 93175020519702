<template>
  <div>
    <h3 class="font-bold text-blue-600 uppercase">Allgemeine Informationen</h3>
    <div class="mt-6 grid grid-cols-12">
      <div class="col-span-9">
        <overview-information :item="booking" />
        <dl v-if="booking.graduation && booking.graduation.name" class="mt-9">
          <definition-item
            headline="Abschlussziel"
            :content="booking.graduation.name"
          />
        </dl>
      </div>
      <div class="col-span-3">
        <div class="overflow-hidden border border-gray-700 rounded">
          <Status
            :status="booking.status"
            :trial-period="booking.trialPeriod"
            :start-date="booking.startDate"
            :cancellation="booking.cancellationDetails"
            :graduation-details="booking.graduationDetails"
            :conditions="booking.conditions"
            outlined
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OverviewInformation from '@/components/shared/OverviewInformation';
import DefinitionItem from '@/components/shared/DefinitionItem';
import Status from '@/components/shared/Status/Status';

export default {
  name: 'GeneralBookingDetails',
  components: {
    OverviewInformation,
    DefinitionItem,
    Status,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
};
</script>
