<template>
  <tr class="odd:bg-blue-100">
    <td class="pl-3 py-3">
      <div class="flex gap-x-1">
        {{ item.program.title }}
        <e-tooltip
          v-if="item.program.description"
          :content="item.program.description"
        >
          <span class="text-cta">
            <e-icon icon="mdi-information-outline" />
          </span>
        </e-tooltip>
      </div>
    </td>
    <td>{{ curriculumTitle }}</td>
    <td>{{ programStart }}</td>
    <td>{{ programEnd }}</td>
    <td>
      <e-menu
        v-if="
          currentStatusLevel &&
          possibleStatusChanges.length > 0 &&
          isEditable &&
          isStatusChangeEnabled
        "
        id="admission-actions"
      >
        <template #activator="{ interact }">
          <div class="cursor-pointer" @click.stop="interact">
            <admission-status :value="item.status">
              <template #additionalContent>
                <e-icon icon="chevron-down" size="sm" />
              </template>
            </admission-status>
          </div>
        </template>

        <div class="mx-2">
          <li v-for="status in availableStatus" :key="status" class="flex">
            <input
              :id="status"
              v-model="selectedStatus"
              type="radio"
              :value="status"
            />
            <button
              class="w-full flex group text-left"
              @click="selectedStatus = status"
            >
              <admission-status :value="status" />
            </button>
          </li>

          <div
            class="
              min-h-12
              bg-orange-50
              text-orange-600
              border-l-8 border-l-orange-600
              my-4
            "
          >
            <div class="m-1 py-2 pl-2">
              <p class="font-semibold mb-1">
                <e-icon icon="mdi-alert-outline" />
                Warnung
              </p>
              <p>Aktionen können nicht widerrufen werden</p>
            </div>
          </div>
          <div class="flex flex-col mt-8 col-span-full min-h-9">
            <e-button
              variant="primary"
              :disabled="
                !isAllowedStatusChange(selectedStatus) || waitingForResponse
              "
              class="ml-auto mt-18"
              @click.stop="handleStatusAction()"
            >
              <e-icon
                v-if="waitingForResponse"
                class="mr-2 animate-spin"
                icon="mdi-loading"
              />
              Anwenden
            </e-button>

            <div class="mt-2 col-span-full self-end">
              <slot name="additional-content" />
            </div>
          </div>
        </div>
      </e-menu>
      <admission-status v-else :value="currentStatus" />
    </td>
    <td>
      <e-tooltip
        content="Bearbeiten"
        class="text-center"
        class-definitions="block"
      >
        <e-button
          small
          class="px-4"
          variant="text"
          :disabled="isEditingDisabled"
          @click="$emit('edit-admission', item)"
        >
          <e-icon color="primary" icon="mdi-pencil-outline" />
        </e-button>
      </e-tooltip>
    </td>
    <td>
      <e-tooltip
        content="Löschen"
        class="text-center"
        class-definitions="block"
      >
        <e-button
          small
          class="px-4"
          variant="text"
          :disabled="
            (item.status !== ADMISSION_PROGRAM_STATUS.DRAFT &&
              item.status !== ADMISSION_PROGRAM_STATUS.IN_PROGRESS) ||
            isEditable === false ||
            isStatusChangeEnabled === false
          "
          @click="$emit('delete-admission', item)"
        >
          <e-icon color="primary" icon="mdi-trash-can-outline" />
        </e-button>
      </e-tooltip>
    </td>
  </tr>
</template>

<script>
import { Actions as AppActions } from '@/store/modules/app/types';
import {
  Actions as BookingActions,
  Getters as BookingGetters,
} from '@/store/modules/booking/types';
import { EButton, EIcon, EMenu, ETooltip } from '@careerpartner/nitro';
import ClickOutside from 'vue-click-outside';
import {
  ADMISSION_PROGRAM_STATUS,
  ADMISSION_PROGRAM_TYPE,
  API_ENDPOINTS,
} from '@/common/constants';
import dayjs from 'dayjs';
import { ApiServiceWrapper, getApiError } from '@/utils/ApiServiceWrapper';
import AdmissionStatus from './AdmissionStatus.vue';

export default {
  name: 'TableRow',
  directives: { ClickOutside },
  components: {
    AdmissionStatus,
    EIcon,
    EMenu,
    ETooltip,
    EButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isStatusChangeEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      waitingForResponse: false,
      ADMISSION_PROGRAM_STATUS,
      selectedStatus: this.item.status,
    };
  },
  computed: {
    booking() {
      return this.$store.getters[
        `booking/${BookingGetters.GET_CURRENT_BOOKING}`
      ];
    },
    currentStatus() {
      return this.allAdmissionStatus.find(
        (status) => status === this.item.status,
      );
    },
    currentStatusLevel() {
      return this.getStatusLevel(this.currentStatus);
    },
    availableStatus() {
      const isCurricular = this.item.type === ADMISSION_PROGRAM_TYPE.CURRICULAR;
      const isNonCurricular =
        this.item.type === ADMISSION_PROGRAM_TYPE.NON_CURRICULAR;

      const filteredAdmissionStatus = this.allAdmissionStatus.filter(
        (status) => status !== ADMISSION_PROGRAM_STATUS.DRAFT,
      );

      if (isCurricular) {
        return filteredAdmissionStatus;
      }

      if (isNonCurricular) {
        return filteredAdmissionStatus.filter(
          (status) => status !== ADMISSION_PROGRAM_STATUS.RESULTS_PENDING,
        );
      }

      return [];
    },
    allAdmissionStatus() {
      return Object.values(ADMISSION_PROGRAM_STATUS);
    },
    isEditingDisabled() {
      if (this.isEditable === false) {
        return true;
      }

      // if there is no curriculum selected
      // it means that the admission program comes from Salesforce
      // and it should be editable
      if (!this.item.curriculum?.id) {
        return false;
      }

      const startInFuture = dayjs(this.item.startDate) > dayjs().startOf('day');
      const changeableState =
        this.item.status === ADMISSION_PROGRAM_STATUS.DRAFT ||
        this.item.status === ADMISSION_PROGRAM_STATUS.IN_PROGRESS;

      return !(startInFuture && changeableState);
    },
    curriculumTitle() {
      return this.item.curriculum?.title || '-';
    },
    programStart() {
      return this.item.startDate || '-';
    },
    programEnd() {
      return this.item.endDate || '-';
    },
    possibleStatusChanges() {
      return this.allAdmissionStatus.filter(
        (status) => this.getStatusLevel(status) > this.currentStatusLevel,
      );
    },
  },
  methods: {
    isAllowedStatusChange(status) {
      return this.possibleStatusChanges.indexOf(status) >= 0;
    },

    getStatusLevel(status) {
      const levels = {
        [ADMISSION_PROGRAM_STATUS.DRAFT]: 0,
        [ADMISSION_PROGRAM_STATUS.IN_PROGRESS]: 1,
        [ADMISSION_PROGRAM_STATUS.RESULTS_PENDING]: 2,
        [ADMISSION_PROGRAM_STATUS.PASSED]: 3,
        [ADMISSION_PROGRAM_STATUS.FAILED]: 3,
      };
      const level = levels[status];
      return level ?? Object.values(ADMISSION_PROGRAM_STATUS).length;
    },

    async reloadBookingWithDelay() {
      await this.$store.dispatch(
        `booking/${BookingActions.LOAD_SINGLE_BOOKING_WITH_DELAY}`,
        this.$route.params.bookingId,
      );
    },
    async handleStatusAction() {
      if (!this.isAllowedStatusChange(this.selectedStatus)) {
        return;
      }

      try {
        this.loading = true;
        this.waitingForResponse = true;

        const requestUrl = API_ENDPOINTS.PATCH_ADMISSION.replace(
          ':bookingId',
          this.booking.id,
        ).replace(':admissionId', this.item.id);

        const payload = {
          status: this.selectedStatus,
        };

        await ApiServiceWrapper.patch(requestUrl, payload);

        await this.$store.dispatch(`app/${AppActions.UPDATE_SNACKBAR}`, {
          message: 'Status wurde erfolgreich angepasst',
        });

        await this.reloadBookingWithDelay();
      } catch (e) {
        this.error = getApiError(e);
      } finally {
        this.waitingForResponse = false;
        this.loading = false;
      }
    },
  },
};
</script>
